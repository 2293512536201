import styled from 'styled-components';

export const LandingPageContainer = styled.div`
  background: ${p => p.theme.palette.semantic.colorBackground};
  color: ${p => p.theme.palette.semantic.colorForeground};
  .LandingPage__Title {
    font-weight: bold;
    font-size: 36px;
    line-height: 38px;
    color: ${p => p.theme.palette.semantic.colorForeground};
    margin: 16px 0;
  }
  .LandingPage__Welcome-Text {
    padding: 1% 25% 6%;
    @media (min-width: 1650px) {
      padding: 3% 33% 6%;
    }
  }
  .LandingPage__Confirmation-Text {
    margin: 2% 25% 30%;
  }
  .LandingPage__Heading {
    font-weight: bold;
    font-size: 28px;
    line-height: 28px;
    margin: 16px 0 28px;
    text-align: center;
    position: relative;
  }
  .LandingPage__SubHeading {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin: 16px 0;
    position: relative;
  }
  .LandingPage__Description {
    font-weight: 200;
    font-size: 18px;
    line-height: 24px;
    margin: 20px 0;
    white-space: pre-wrap;
    position: relative;
  }
  .LandingPage__Button-Container {
    display: flex;
    flex-direction: column;
    margin: 8% 24% 0;
    .LandingPage__Button {
      margin-bottom: 28px;
      font-size: 16px !important;
      padding: 10px 16px;
      background-color: ${p => p.theme.palette.semantic.colorPrimary};
      color: ${p => p.theme.palette.semantic.colorBackground};
      &:hover {
        background-color: ${p => p.theme.palette.semantic.colorPrimary};
        opacity: 0.9;
      }
    }
  }
  a {
    color: ${p => p.theme.palette.semantic.colorPrimary};
    &:hover {
      cursor: pointer;
    }
  }
`;
