import {
  FormControl,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
} from '@mui/material';
import {
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useTheme } from 'styled-components';

import * as styles from '../../../assets/css/HAPO/RequestHistory/AddHistoryOrder';
import { CPTableContainer } from '../../../assets/css/Table';
import {
  axiosPost,
  RequestType,
  useAxios,
} from '../../../client/axios';
import CustomerPortalLoader from '../../../components/CustomerPortal-Loader';
import Container from '../../../components/CustomerPortal-New-Container';
import ScrollToTop from '../../../components/CustomerPortal-ScrollToTop';
import SubmitButtonsContainer from '../../../components/HAPO/AddHistory/SubmitButtonsContainer';
import SelectWithSearch from '../../../components/Select-With-Search';
import TextInput from '../../../components/Text-Input';
import TextLabel from '../../../components/TextLabel';
import { LICENSE_VERSIONS } from '../../../constants/hapo.constants';
import { HAPO_LICENSES_URL } from '../../../constants/network.constants';
import { useAuth } from '../../../contexts/auth';
import type { DropDownItemData } from '../../../interfaces/sfdc.interface';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import { StoreContext } from '../../../store';

const AddHistoryOrder = (props: any) => {
  const { t } = useTranslation('common');
  const isCompleteHAPOOrderAllowed = UserPermissionsHelper.isCompleteHAPOOrderAllowed();
  const history = useHistory();
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const theme = useTheme();
  const { getAccessToken } = useAuth();
  const [ canSubmit, setCanSubmit ] = useState<boolean>(false);
  const [ changedSku, setChangedSku ] = useState<
  { [key: string]: string } | undefined
  >(undefined);
  const [ orderProperties, setOrderProperties ] = useState({
    version: {
      label: '',
      value: '',
    },
    note: '',
    startDate: new Date(),
  });
  const [ hapoConfigSKUs, hapoConfigSKUsIsLoading ] = useAxios({
    url: `${HAPO_LICENSES_URL}/forms/add_order`,
    requestType: RequestType.GET,
  });

  useEffect(() => {
    // check for required fields
    setCanSubmit(orderProperties.version.value !== '' && Object.keys(changedSku ?? {}).length > 0);
  }, [ orderProperties, changedSku ]);

  if (!isCompleteHAPOOrderAllowed) {
    return <Redirect to="/unauthorized" />;
  }

  // Event Handlers
  const handleQuantityInputOnChange = (productCode: string, val: string) => {
    if ([ '0', '' ].includes(val)) {
      const newChangedSku = { ...changedSku };
      delete newChangedSku[productCode];
      setChangedSku(newChangedSku);
      return;
    }

    setChangedSku({
      ...changedSku,
      [productCode]: val,
    });
  };

  const handleSelectOptionChange = (
    option: DropDownItemData,
    propertyName: string
  ) => {
    setOrderProperties({
      ...orderProperties,
      [propertyName]: option,
    });
  };

  const handleInputChange = (propertyName: string, value: string | Date) => {
    setOrderProperties({
      ...orderProperties,
      [propertyName]: value,
    });
  };

  const handleQuantityFormSubmit = async () => {
    const changedSkuFinal: { [key: string]: number } = {};
    const {
      version, note, startDate,
    } = orderProperties;

    for (const productCode of Object.keys(changedSku ?? {})) {
      changedSkuFinal[productCode] = parseInt(changedSku![productCode], 10);
    }

    const formData = {
      changedSkus: changedSkuFinal,
      version: version.value,
      note,
      start_date: startDate,
    };

    try {
      await axiosPost(
        `${HAPO_LICENSES_URL}/order/history`,
        state.companyId,
        await getAccessToken(),
        formData
      );
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t('request_submitted_successful', 'Request has been created.'),
      });
      history.replace('/hapo/request-history');
    } catch (e) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      const errMessage = e.response?.data?.message ?? e.message;
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'hapo_checkout_cart_error_msg',
          `There was an error in submitting your request. Please try again. ${errMessage}`,
          { errMessage }
        ),
      });
    }
  };

  const generateForm = (
    licensesList: Array<{ productCode: string; name: string }>
  ) =>
  // Column 1 - Product Code
  // Column 2 - SKU Name
  // Column 3 - Quantity

    (
      <CPTableContainer className="Table__Normal">
        <Table
          size="small"
          aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Product Code</TableCell>
              <TableCell align="left">SKU</TableCell>
              <TableCell align="left">Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {licensesList.map(license => (
              <TableRow key={license.name}>
                <TableCell>{license.productCode}</TableCell>
                <TableCell>{license.name}</TableCell>
                <TableCell align="right">
                  <FormControl>
                    <TextInput
                      type="number"
                      ariaLabel={license.productCode + 'quantity input'}
                      id={
                        'add-order-modal-' + license.productCode + '-textInput'
                      }
                      defaultValue="0"
                      placeholder="0"
                      onChange={(
                        e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        handleQuantityInputOnChange(
                          license.productCode,
                          e.target.value
                        );
                      }}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CPTableContainer>
    )
  ;

  const handleGoBack = () => {
    history.go(-1);
  };

  return (
    <div data-testid="HapoAddHistoryOrder">
      {hapoConfigSKUsIsLoading && (
        <div data-testid="HapoAddHistoryOrder__Loading">
          <CustomerPortalLoader />
        </div>
      )}

      {!hapoConfigSKUsIsLoading && hapoConfigSKUs?.data && (
        <>
          <ScrollToTop />
          <Helmet>
            <title>{t('add_order', 'Add Order')}</title>
          </Helmet>

          <styles.PageWrapper data-testid="HapoAddHistoryOrder__form">
            <styles.HomeSection>
              <Container cssClass="CustomerPortalPage__container">
                <div
                  className="heroBackButton"
                  onClick={() => {
                    handleGoBack();
                  }}
                  data-testid="HapoRequestHistory__goBackBtn"
                >
                  {t('past_requests_hapo_go_back_btn', 'Go Back')}
                </div>

                <styles.heroTitleWrapper>
                  <styles.heroTitle>
                    {t('add_order', 'Add Order')}
                  </styles.heroTitle>
                </styles.heroTitleWrapper>

                <styles.FormTableContainer>
                  <div className="AddHistoryOrder__FormDataContainer">
                    <FormControl>
                      <SelectWithSearch
                        label={t(
                          'hapo_checkout_version_select_field_label',
                          'Version'
                        )}
                        required
                        searchable
                        className="Custom-Select--White AddHistoryOrder__SelectVersion"
                        options={LICENSE_VERSIONS.map(option => ({
                          label: option.label,
                          value: option.value,
                        }))}
                        value={orderProperties.version}
                        onChange={(o: DropDownItemData) =>
                          handleSelectOptionChange(o, 'version')}
                      />
                    </FormControl>
                    <FormControl className='AddHistoryOrder__StartDatePicker'>
                      <TextLabel
                        label={t('support_premium_care_form_start_date_label', 'Start Date')}
                        required
                      />
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          view='day'
                          views={[ 'day' ]}
                          openTo='day'
                          slots={{
                            openPickerButton: (props) => (
                              <IconButton
                                {...props}
                                data-testid='StartDatePicker' />
                            ),
                            openPickerIcon: () => (
                              <Icon className='material-icons-outlined'>
                                calendar_today
                              </Icon>
                            ),
                          }}
                          slotProps={{
                            textField: {
                              id: 'date-picker-inline',
                              className: 'Date-Picker',
                              required: true,
                              InputProps: { className: 'Tall' },
                            },
                            field: {
                              className: 'Date-Picker',
                              readOnly: true,
                            },
                            toolbar: { hidden: true },

                          }}
                          format="MM/dd/yyyy"
                          className="Date-Picker"
                          minDate={new Date()}
                          value={orderProperties.startDate}
                          onChange={(date: any) => {
                            handleInputChange('startDate', date);
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                  {generateForm(hapoConfigSKUs.data)}
                  <FormControl style={{ width: '100%' }}>
                    <TextLabel
                      label={t('hapo_checkout_notes_text_field_label', 'Notes')}
                    />
                    <TextareaAutosize
                      placeholder={t(
                        'support_form_description_placeholder',
                        'Please add a detailed description here...'
                      )}
                      value={orderProperties.note}
                      onChange={e => handleInputChange('note', e.target.value)}
                      aria-label={t(
                        'hapo_checkout_notes_text_field_label',
                        'Notes'
                      )}
                      minRows={5}
                      data-testid="description-input"
                      style={{
                        backgroundColor: theme.palette.semantic.colorBackground,
                        color: theme.palette.semantic.colorForeground,
                      }}
                    />
                  </FormControl>
                  <SubmitButtonsContainer
                    disabled={!canSubmit}
                    handleFormSubmit={handleQuantityFormSubmit}
                    handleCancelButtonClick={handleGoBack}
                  />
                </styles.FormTableContainer>
              </Container>
            </styles.HomeSection>
          </styles.PageWrapper>
        </>
      )}
    </div>
  );
};

export default AddHistoryOrder;
