import { UserStatus } from '@customer-portal/constants';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import axios from 'axios';
import DOMPurify from 'dompurify';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { CloudMigrationContainer } from '../../assets/css/CloudMigration';
import { axiosPost } from '../../client/axios';
import Label from '../../components/Label';
import {
  CLOUD_DOMAIN,
  CLOUD_SERVICE_NAME,
} from '../../constants/auth.constants';
import { CONNECT_TO_CLOUD_ORG_URL } from '../../constants/network.constants';
import {
  CLOUD_CONNECT_CANCEL_MIGRATION_FROM_CP_AND_RETURN_TO_CP,
  CLOUD_CONNECT_CANCEL_MIGRATION_FROM_DIFFERENT_ORG_AND_RETURN_TO_SWITCH_ENVIRONMENT,
  CLOUD_CONNECT_CONFIRM,
  CLOUD_CONNECT_FIRST_PAGE_VIEW,
  CLOUD_CONNECT_NOT_ALLOWED_PAGE_VIEW,
  CLOUD_CONNECT_RETURN_TO_CP,
  CLOUD_CONNECT_RETURN_TO_SWITCH_ENVIRONMENT,
  CLOUD_CONNECT_SECOND_PAGE_VIEW,
  CLOUD_CONNECT_SIGN_OUT_AND_GO_TO_DIFFERENT_ORG,
  CLOUD_MIGRATION,
} from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import { CloudOrgContext } from '../../contexts/cloud-org';
import useLogout from '../../hooks/useLogout';
import type { IDataObject } from '../../interfaces/dataObject.interface';
import {
  useTrackPageViewEvent,
  useTriggerTrackEventWithStateData,
} from '../../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
import {
  CLOUD_ORGANIZATION_INVITE_USERS_MAX_BATCH_SIZE,
  getCloudOrganizationInviteUsersUrl,
  getWebRedirectOrigin,
} from '../../utils/cloud';
import { clearSession } from '../../utils/session';
import CustomerPortalPage401 from '../401';

const CustomerPortalToCloudMigration = (props: any) => {
  const { t } = useTranslation('common');
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const history = useHistory();
  const {
    isLoadingCloudOrgDetails, cloudOrgName, cloudOrgLogicalName, isCloudOrgAdmin,
  } = useContext(CloudOrgContext);
  const [ showErrorPage, setShowErrorPage ] = useState(false);
  const [ showConfirmationPage, setShowConfirmationPage ] = useState(false);
  const [ isConnecting, setIsConnecting ] = useState(false);
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();
  const logout = useLogout();
  useTrackPageViewEvent(CLOUD_MIGRATION);
  const baseClass = 'CloudMigration';

  const hasMoveToCloudPermissions = UserPermissionsHelper.isMoveToCloudAllowed();

  // Determines whether cancellation should return to Web URL
  const isCompanyOnCloud = !!(state.currentUserCompany?.additionalCompanyInfo?.cloudOrgId);

  const [ teamUsersToInvite, setTeamUsersToInvite ] = useState<Array<{
    email: string;
    status: string;
  }>>([]);
  const [ selectedTeamUsersToInvite, setSelectedTeamUsersToInvite ] = useState<string[]>([]);

  useEffect(() => {
    const stateTeamUsersToInvite = state.teamUsers?.filter((user: IDataObject) => !user.cloudOrgProfile)
      .map((user: IDataObject) => ({
        email: user.Email as string,
        status: user.status as string,
      })) ?? [];
    setTeamUsersToInvite(stateTeamUsersToInvite);
    setSelectedTeamUsersToInvite(stateTeamUsersToInvite
      .filter(({ status }) => status !== UserStatus.DISABLED)
      .map(({ email }) => email));
  }, [ state.teamUsers ]);

  useEffect(() => {
    if (!isLoadingCloudOrgDetails && cloudOrgName && cloudOrgLogicalName) {
      if (isCloudOrgAdmin) {
        setShowErrorPage(false);
        triggerTrackEventWithStateData(CLOUD_CONNECT_FIRST_PAGE_VIEW);
      } else {
        // If user is NOT cloud admin, show error page
        setShowErrorPage(true);
        triggerTrackEventWithStateData(CLOUD_CONNECT_NOT_ALLOWED_PAGE_VIEW);
      }
    }
  }, [ isLoadingCloudOrgDetails, cloudOrgName, cloudOrgLogicalName, isCloudOrgAdmin ]);

  const continueToNextStep = () => {
    setShowConfirmationPage(true);
    triggerTrackEventWithStateData(CLOUD_CONNECT_SECOND_PAGE_VIEW);
  };

  const onConfirm = async () => {
    setIsConnecting(true);
    triggerTrackEventWithStateData(CLOUD_CONNECT_CONFIRM);

    try {
      // InviteUsers API handles only 10 users at a time
      for (let i = 0; i < selectedTeamUsersToInvite.length; i += CLOUD_ORGANIZATION_INVITE_USERS_MAX_BATCH_SIZE) {
        const teamUserEmailBatch = selectedTeamUsersToInvite.slice(i, i + CLOUD_ORGANIZATION_INVITE_USERS_MAX_BATCH_SIZE);
        await axios.post(getCloudOrganizationInviteUsersUrl(),
          {
            inviteData: teamUserEmailBatch.map((email: string) => ({
              name: email.split('@')[0],
              email,
            })),
          },
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              'Content-Type': 'application/json',
            },
          });
      }
    } catch (e) {
      console.error('An error occurred while inviting users to Automation Cloud organization:', e);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'cloud_migration_invite_users_error_message',
          'Something went wrong while inviting users to your Automation Cloud organization.',
        ),
      });
      setIsConnecting(false);
      return;
    }

    try {
      await axiosPost(
        CONNECT_TO_CLOUD_ORG_URL,
        state.companyId,
        await getAccessToken(),
        {}
      );
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerAutoHide',
        payload: false,
      });
      dispatch({
        type: 'setBannerMsg',
        payload: selectedTeamUsersToInvite.length > 0 ?
          t(
            'cloud_migration_success_banner_message_with_invitations',
            'Your Customer Portal account has been successfully connected to your Automation Cloud organization! Invitations to the Automation Cloud organization have been sent to the selected users.',
          ) :
          t(
            'cloud_migration_success_banner_message',
            'Your Customer Portal account has been successfully connected to your Automation Cloud organization!',
          ),
      });
    } catch (e) {
      console.error('An error occurred while connecting Customer Portal account to Automation Cloud organization:', e);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'cloud_migration_connect_to_cloud_error_message',
          'Something went wrong while connecting your Customer Portal account to your Automation Cloud organization.'
        ),
      });
      setIsConnecting(false);
      return;
    }
    // Ensure that the next redirect waits until user context is re-fetched before the App renders
    // (prevents flickering before the next user response returns that the account is now connected to Cloud org)
    dispatch({
      type: 'setFinishedInitialUserCheck',
      payload: false,
    });
    history.push('/');
  };

  const onSignOutAndGoToDifferentOrg = () => {
    triggerTrackEventWithStateData(CLOUD_CONNECT_SIGN_OUT_AND_GO_TO_DIFFERENT_ORG);
    logout();
  };

  const onCancel = (fromErrorPage: boolean) => {
    triggerTrackEventWithStateData(fromErrorPage ?
      (isCompanyOnCloud ?
        CLOUD_CONNECT_RETURN_TO_SWITCH_ENVIRONMENT :
        CLOUD_CONNECT_RETURN_TO_CP) :
      (isCompanyOnCloud ?
        CLOUD_CONNECT_CANCEL_MIGRATION_FROM_DIFFERENT_ORG_AND_RETURN_TO_SWITCH_ENVIRONMENT :
        CLOUD_CONNECT_CANCEL_MIGRATION_FROM_CP_AND_RETURN_TO_CP));

    if (isCompanyOnCloud) {
      history.goBack();
    } else {
      clearSession();
      window.location.href = `${getWebRedirectOrigin()}/`;
    }
  };

  const onTeamUserInviteCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value, checked,
    } = e.target;
    if (checked) {
      setSelectedTeamUsersToInvite([ ...selectedTeamUsersToInvite, value ]);
    } else {
      setSelectedTeamUsersToInvite(selectedTeamUsersToInvite.filter((email) => email !== value));
    }
  };

  const onTeamUserInviteSelectOrDeselectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedTeamUsersToInvite(teamUsersToInvite.map(({ email }) => email));
    } else {
      setSelectedTeamUsersToInvite([]);
    }
  };

  const connectToCloudPromptMessage = () => (
    <div>
      <div className={`${baseClass}__Heading-Text`}>
        {t('cloud_migration_heading_message', 'Connect your Customer Portal Account to your UiPath Automation Cloud Organization')}
      </div>
      <div
        className={`${baseClass}__Text`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(t('cloud_migration_description_message',
            'You are trying to connect <b>{{cpAccountName}}</b> to your Automation Cloud Organization <b>{{cloudOrgName}}</b>.',
            {
              cpAccountName: state.companyName,
              cloudOrgName,
            }
          )),
        }}
      />
      <br />

    </div>
  );

  // TODO: Finalize this logic
  if (!hasMoveToCloudPermissions) {
    return <CustomerPortalPage401 />;
  }

  if (isLoadingCloudOrgDetails) {
    return null;
  }

  return (
    <CloudMigrationContainer>
      <Helmet>
        <title>
          {t(
            'cloud_migration_page_title',
            'Cloud Migration | Customer Portal'
          )}
        </title>
      </Helmet>
      {showErrorPage ? (
        <div>
          {connectToCloudPromptMessage()}
          <div className={`${baseClass}__Text`}>
            {t('cloud_migration_description_error_message', 'This step must be completed by a Cloud Admin (a member of the \'Administrators\' group). As a user, you are unable to complete the process of connecting your Customer Portal account to this Automation Cloud organization.')}
          </div>
          <div className={`${baseClass}__Bolded-Text`}>
            {t('cloud_migration_action_to_take', 'Action to take:')}
          </div>
          <div className={`${baseClass}__Text`}>
            <ul>
              <li>{t('cloud_migration_action_to_take_step_1', 'Add a Cloud Admin to your Customer Portal account.')}</li>
              <li>{t('cloud_migration_action_to_take_step_2', 'Request that the Cloud Admin log in to Customer Portal to connect the accounts.')}</li>
            </ul>
          </div>
          <div className={`${baseClass}__Button-Container`}>
            <Button
              variant='contained'
              color='secondary'
              className={`${baseClass}__Button`}
              onClick={onSignOutAndGoToDifferentOrg}
            >
              {t('cloud_migration_sign_out_and_go_to_different_org_button', 'Sign out and connect to a different Automation Cloud Organization')}
            </Button>
            <Button
              variant='contained'
              color='secondary'
              className={`${baseClass}__Button`}
              onClick={() => onCancel(true)}
            >
              {isCompanyOnCloud ? t('cloud_migration_return_to_switch_environment_button', 'Return') : t('cloud_migration_return_to_customer_portal_button', 'Return to Customer Portal')}
            </Button>
          </div>
        </div>
      ) : showConfirmationPage ? (
        <div>
          <div className={`${baseClass}__Heading-Text`}>
            {t('cloud_migration_complete_the_process_message', 'Complete the process to connect your Customer Portal account to your Automation Cloud organization')}
          </div>
          {teamUsersToInvite.length > 0 && (
            <>
              <div
                className={`${baseClass}__Text`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t('cloud_migration_list_of_users_message', 'The following users of <b>{{cpAccountName}}</b> require an invite to your Automation Cloud organization <b>{{cloudOrgName}}</b>. Confirm which users should be invited to the <b>\'Everyone\'</b> group in your Automation Cloud organization.',
                    {
                      cpAccountName: state.companyName,
                      cloudOrgName,
                    })),
                }} />

              {/* Form where they can select which users in teamUsersToInvite should be invited */}
              <div className={`${baseClass}__User-List ${baseClass}__Text`}>
                <FormControlLabel
                  className={`${baseClass}__Select-All-Invite-Checkbox`}
                  control={
                    <Checkbox
                      name="selectAll"
                      onChange={onTeamUserInviteSelectOrDeselectAll}
                      checked={selectedTeamUsersToInvite.length === teamUsersToInvite.length}
                    />
                  }
                  label={t('cloud_migration_select_all_button', 'Select all')}
                />

                {teamUsersToInvite.map(({
                  email, status,
                }, i: number) => (
                  <FormGroup key={i}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={`${baseClass}__Invite-Checkbox`}
                          onChange={onTeamUserInviteCheckboxChange}
                          checked={selectedTeamUsersToInvite.includes(email)}
                          name={email}
                          id={email}
                          value={email}
                        />
                      }
                      label={
                        <div className={`${baseClass}__Invite-Checkbox-Label`}>
                          <span>
                            {email}
                          </span>
                          <Label
                            text={status}
                            type={status} />
                        </div>
                      }
                    />
                  </FormGroup>
                ))}
              </div>
            </>
          )}
          <div className={`${baseClass}__Button-Container`}>
            <Button
              variant='contained'
              color='primary'
              className={`${baseClass}__Button`}
              onClick={onConfirm}
              disabled={isConnecting}
            >
              {isConnecting ? t('cloud_migration_button_connecting_message', 'Connecting...') : t('cloud_migration_confirm_button', 'Confirm')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              className={`${baseClass}__Button`}
              onClick={() => onCancel(false)}
              disabled={isConnecting}
            >
              {isCompanyOnCloud ? t('cloud_migration_cancel_button', 'Cancel') : t('cloud_migration_cancel_and_return_to_customer_portal_button', 'Cancel and return to Customer Portal')}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {connectToCloudPromptMessage()}
          <div className={`${baseClass}__Bolded-Text`}>
            {t('cloud_migration_important_note', 'Important Note:')}
          </div>
          <div className={`${baseClass}__Text`}>
            <ul>
              <li>{t('cloud_migration_important_note_text', 'If needed, sign out and log in to a different Automation Cloud Organization.')}</li>
            </ul>
          </div>
          <div className={`${baseClass}__Bolded-Text`}>
            {t('cloud_migration_outcome', 'Migration Outcome:')}
          </div>
          <div className={`${baseClass}__Text`}>
            <ul>
              <li>{t('cloud_migration_outcome_text_1', 'After completing the migration, this Customer Portal account will only be accessible through this Automation Cloud Organization.')}</li>
              <li dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(t('cloud_migration_outcome_text_2', 'The URLs for this Customer Portal account will change to <b>{{cloudURL}}</b>',
                  { cloudURL: `${CLOUD_DOMAIN}/${cloudOrgLogicalName}/${CLOUD_SERVICE_NAME}` })),
              }} />
            </ul>
          </div>
          <div className={`${baseClass}__Button-Container`}>
            <Button
              variant='contained'
              color='secondary'
              className={`${baseClass}__Button`}
              onClick={continueToNextStep}>
              {t('cloud_migration_continue_button', 'Continue to next step')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              className={`${baseClass}__Button`}
              onClick={() => onCancel(false)}
            >
              {isCompanyOnCloud ? t('cloud_migration_cancel_button', 'Cancel') : t('cloud_migration_cancel_and_return_to_customer_portal_button', 'Cancel and return to Customer Portal')}
            </Button>
          </div>
        </div>
      )}
    </CloudMigrationContainer>
  );
};

export default CustomerPortalToCloudMigration;
