import MuiAppBar from '@material-ui/core/AppBar';
import {
  BottomNavigation,
  Drawer,
} from '@mui/material';
import styled from 'styled-components';

import { transitionStandard } from '../../assets/js_mixins/transitions';
import Container from '../../components/CustomerPortal-New-Container';

export interface HeaderProps {
  headerCount?: number;
  isCheckout?: boolean;
}

export const CustomerPortalHeaderInnerMobileDrawer = styled(Drawer)<HeaderProps>`
  && {
    @media (min-width: ${p => p.headerCount && p.headerCount > 4 ? '1238px' : '1106px'}) {
      display: none;
    }
    .MuiDrawer-paper {
      width: 100%;
    }

    .MuiPaper-root {
      background-color: ${p => p.theme.palette.semantic.colorBackground};
    }
  }
`;

export const CustomerPortalHeaderInner = styled(Container)<HeaderProps>`
  && {
    max-width: unset;
    ${p => p.isCheckout && `
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `}
    @media (max-width: 1105px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &.CustomerPortalHeader__Inner-Mobile {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    }
  }
`;

export const PublicCustomerPortalHeaderInner = styled(Container)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &.CustomerPortalHeader__Inner-Mobile {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    }
  }
  .PublicCustomerPortalHeader__Login-Button {
    font-size: 16px !important;
    background-color: ${p => p.theme.palette.semantic.colorPrimary};
    color: ${p => p.theme.palette.semantic.colorForegroundInverse};
    padding: 8px 0;
    width: 180px;
    &:hover {
      background-color: ${p => p.theme.palette.semantic.colorPrimary};
      opacity: 0.9;
    }
  }
`;

export const NavBarBottom = styled(BottomNavigation)`
  && {
    padding: 22px 16px;
    height: 92px;
  }
`;

export const NavBarOuter = styled(MuiAppBar)`
  && {
    flex: 1 100%;
    justify-content: center;
    background-color: inherit;
    box-shadow: none;
    position: relative;
  }
`;

export const PublicNavBarOuter = styled(MuiAppBar)`
  && {
    z-index: 1100;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    box-shadow: none;
    border-bottom: 1px solid ${p => p.theme.palette.semantic.colorBackgroundGray};
    position: relative;
    @media (max-width: 1023px) {
      height: 64px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
`;

export const NavOpener = styled.button`
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  margin-left: auto;
`;

export const HamburgerLine = styled.div<{ navOpen?: boolean }>`
  width: 20px;
  border-radius: 1px;
  height: 2px;
  background-color: ${p => p.theme.palette.semantic.colorForegroundInvDeEmp};
  ${transitionStandard('all')}
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  &:nth-child(2) {
    margin: 4px 0;
    opacity: 1;
  }
  ${(p): string =>
    p.navOpen
      ? `
      background-color: ${p.theme.palette.semantic.colorForegroundInvDeEmp};
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg);
        top: 50%;
        position: absolute;
      }
      &:nth-child(2){
        -webkit-transform: rotate(25deg);
            -ms-transform: rotate(25deg);
                transform: rotate(25deg);
        opacity: 0
      }
      &:nth-child(3){
        -webkit-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
                transform: rotate(135deg);
        top: 50%;
        position: absolute;
      }
    `
      : ''};
`;

export const NavOpenerInner = styled.div<HeaderProps>`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  @media (min-width: ${p => p.headerCount && p.headerCount > 4 ? '1238px' : '1106px'}) {
    display: none;
  }
  &:hover ${HamburgerLine} {
    background-color: ${p => p.theme.palette.semantic.colorBackgroundGrayEmp};
  }
`;

export const HeaderLogoHamburgerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .CustomerPortalHeader__Logo {
    width: 225px;
    display: block;
    .CustomerPortalHeader__Logo-Img {
      width: 100%;
    }
  }
`;

export const PublicHeaderLanguageSelectorAndLoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .VerifyEmailPage__BottomOptions__languageSelector {
    margin-right: ${p => p.theme.spacing(4)}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .SelectLanguage__icon {
      width: ${p => p.theme.spacing(2.5)}px;
      fill: ${p => p.theme.palette.semantic.colorForeground};
    }

    .VerifyEmailPage__BottomOptions__languageSelectorBtn {
      height: 36px;

      span {
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 20px;
      }

      img {
        width: ${p => p.theme.spacing(5)}px;
      }

      &[aria-expanded="true"]{
        img {
          transform: rotate(-180deg);
        }
      }
    }

    .VerifyEmailPage__BottomOptions__languagesDropdown {
      min-width: ${p => p.theme.spacing(20)}px;
      box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);

      .MuiPaper-root {
        background-color: ${p => p.theme.palette.semantic.colorBackgroundRaised};
        color: ${p => p.theme.palette.semantic.colorForeground};
      }

      .VerifyEmailPage__BottomOptions__languageItem {
        font-size: 1.4rem;
        line-height: 20px;

        &--active {
          box-shadow: inset 4px 0px 0px ${p => p.theme.palette.semantic.colorPrimary};
          background-color: ${p => p.theme.palette.semantic.colorBackgroundSelected};
        }
      }
    }
  }
`;

export const CustomerPortalHeaderUserProfile = styled.div`
  ${p => p.theme.breakpoints.down('lg')} {
    flex: 1;
  }
`;

export const CustomerPortalHeaderActions = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${p => p.headerCount && p.headerCount > 4 ? '1238px' : '1106px'}) {
    display: none;
  }
`;

export const CustomerPortalHeaderTextItems = styled.div`
  ${p => p.theme.breakpoints.down('sm')} {
    margin-right: 40px;
  }
`;

export const CustomerPortalHeaderMenuList = styled.ul`
  position: relative;
  list-style: none;
  padding: 5rem 3rem 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    flex: 0 1 auto;
    padding: 0;
  }
`;

export const CustomerPortalHeaderNonTextItems = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomerPortalHeaderNotifications = styled.div`
  margin-right: 16px;
  ${p => p.theme.breakpoints.down('sm')} {
    flex: 0;
    margin-right: 0;
  }
`;

export const CheckoutContinueShoppingButtonContainer = styled.div`
  margin: 0;
  min-width: 154px;
  .MuiButton-root {
    text-transform: none;
    font-size: 14px;
    height: 30px;
    font-weight: 600;
    border-radius: 3px;
  }
  .MuiButton-textSecondary {
    color: ${p => p.theme.palette.semantic.colorPrimary};
    &:hover {
      background-color: ${p => p.theme.palette.semantic.colorPrimaryHover};
    }
  }
`;
