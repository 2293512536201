export const DOMAIN_PREFIX_TO_CLOUD_COOKIE_NAME_SUFFIX = new Map([
  [ 'id-alpha', 'alp' ],
  [ 'id-preprod', 'stg' ],
  [ 'account', 'prd' ],
  [ 'local', 'local' ],
]);

export const CLOUD_ORG_ADMINISTRATORS_GROUP = 'Administrators';

/**
 * When resolving users within a Cloud org, the following value for the 'source' field shows that the user
 * is a Local user (as opposed to a directory AAD/SAML user).
 * See API at https://cloud.uipath.com/identity_/swagger/index.html
 */
export const CLOUD_ORG_USER_RESOLVE_SOURCE_LOCAL = 'local';
