import {
  Chip,
  Menu,
  Popover,
} from '@mui/material';
import styled from 'styled-components';

import { transitionStandard } from '../../js_mixins/transitions';
import { ContentWrapper } from '../CustomerPortalContentWrapper';

export const LicenseInfoQuantityWidth = 70;
export const LicenseInfoQuantityFontSize = 14;

export const MyCompanyPageWrapper = styled.div`
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  height: unset;
  ${ContentWrapper}
  .CustomerPortalPageMyCompany__MainTitle {
    margin-bottom: 34px;
  }
  .CustomerPortalPageMyCompany__SearchContainer {
    margin-bottom: 20px;
  }
  .CustomerPortalPageMyCompany__SearchContainer__Tag {
    background: ${p => p.theme.palette.primary.light};
    color: ${p => p.theme.palette.semantic.colorPrimary};
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    padding: 4px 10px;
    border-radius: 2px 2px;
    margin-right: ${p => p.theme.spacing(2)}px;
    border-color: ${p => p.theme.palette.primary.light};
    cursor: pointer;
  }
  .CustomerPortalPageMyCompany__SearchContainer__Tag:focus {
    outline: none;
  }
`;

export const MyCompanyMain = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  .CustomerPortalPageMyCompany__SectionContainer {
    margin-bottom: 100px;
  }

  .CustomerPortalPageMyCompany__DocumentsContainer__Header{
    margin-bottom: ${p => p.theme.spacing(4)}px;
    padding-left: ${p => p.theme.spacing(3)}px;
  }
`;

export const MyCompanyBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
    top: -48px;
    position: relative;
  }
`;

export const CompanyBody = styled.div`
  max-width: 918px;
  width: 100%;
  padding-right: 24px;
  box-sizing: border-box;
  ${p => p.theme.breakpoints.down('sm')} {
    padding-right: 0;
    max-width: 100%;
    margin-top: 24px;
  }

  .MyCompanyBody__License-Owner {
    p {
      margin: 0;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 0.5px;
      color: ${p => p.theme.palette.semantic.colorForeground};
    }
  }

  .MyCompanyBody__Loader {
    display: flex;
    justify-content: center;
  }

  .MyCompanyBody__Body-Heading {
    margin-bottom: ${p => p.theme.spacing(0.5)}px;
  }

  .MyCompanyBody__Body-Subheading {
    max-width: 610px;
  }

  .MyCompanyBody__Body-Heading,
  .MyCompanyBody__Body-Subheading {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  .MyCompanyBody__List {
    display: flex;
    flex-direction: column;
    margin-top: ${p => p.theme.spacing(4)}px;
    padding-bottom: ${p => p.theme.spacing(8)}px;
    ${p => p.theme.breakpoints.down('sm')} {
      padding-bottom: ${p => p.theme.spacing(4)}px;
    }
  }

  .MyCompanyBody__Heading-Actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .MyCompanyBody__Heading-Text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .MyCompanyBody__Add-User-Opener {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    background: ${p => p.theme.palette.semantic.colorPrimary};
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    padding-left: ${p => p.theme.spacing(3)}px;
    border-radius: ${p => p.theme.spacing(1)}px;
    margin-bottom: ${p => p.theme.spacing(3)}px;
    h6 {
      color: ${p => p.theme.palette.semantic.colorBackground};
    }
    .MyCompanyBody__Add-User-Icon {
      width: 24px;
      height: 24px;
      margin-right: ${p => p.theme.spacing(3)}px;
    }
  }

  /* For download csv button ONLY on License section */
  .MyCompanyBody__Licenses {
    .MyCompanyBody__Body-Subheading-Outer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      ${p => p.theme.breakpoints.down('sm')} {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .MyCompanyBody__Body-ButtonCSV {
      border-radius: 8px;
      padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(1.5)}px;
      color: ${p => p.theme.palette.semantic.colorBackground};

      ${p => p.theme.breakpoints.down('sm')} {
        margin-top: ${p => p.theme.spacing(2)}px;
      }
    }

    .CustomerPortalClientsSelection__clientsFilterEntity {
      min-width: 200px;
      margin: 0 16px;
    }
  }

  .MyCompanyBody__License-Table-Column-Title, .HAPO__License-Table-Column-Title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 8px 24px 40px;
    ${p => p.theme.breakpoints.down('md')} {
      padding: 0 12px 24px 8px;
    }
    ${p => p.theme.breakpoints.down('sm')} {
      padding: 0 0 0 42px;
      letter-spacing: 0.5px;
    }
    p {
      color: #858f95;
    }
    .MyCompanyBody__License-Column {
      &--activations,
      &--subscription,
      &--start-date,
      &--end-date,
      &--status {
        ${p => p.theme.breakpoints.down('sm')} {
          display: none;
        }
      }
    }
  }

  .MyCompanyBody__License-Column--Opener-Icon {
    cursor: pointer;
    opacity: 0.5;
    ${transitionStandard('opacity')};
    &:hover {
      opacity: 1;
    }
  }

  .MyCompanyBody__License-Column {
    &--details {
      display: flex;
      flex-wrap: wrap;
      flex: 5;
      ${p => p.theme.breakpoints.down('sm')} {
        flex: 100%;
        margin-bottom: ${p => p.theme.spacing(2)}px;
        display: flex;
        flex-direction: row;
      }
      .MyCompanyBody__License-Column-Serial {
        margin: 8px 0 0 0;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 0.5px;
        color: ${p => p.theme.palette.semantic.colorForegroundDeEmp} !important;
      }
    }
    &--name {
      flex: 4;
    }
    .MyCompanyBody__License-Column-Value {
      letter-spacing: 0.5px;
    }
    &--activations,
    &--status {
      ${p => p.theme.breakpoints.up('md')} {
        text-align: center;
      }
    }
    &--activations {
      flex: 2;
      ${p => p.theme.breakpoints.down('md')} {
        margin-left: 32px;
      }
    }
    &--subscription,&--created-by {
      flex: 2;
      padding: 0px ${p => p.theme.spacing(2)}px;
    }
    &--start-date,&--last-updated-by,&--entity-name {
      flex: 2;
    }
    &--end-date,&--total-resources {
      flex: 2;
    }
    &--status {
      flex: 2;
      margin-left: auto;
      flex-direction: row;
      justify-content: center;
      display: flex;
      ${p => p.theme.breakpoints.down('sm')} {
        display: none;
      }
    }
    &--opener {
      flex: 0 1 auto;
      ${p => p.theme.breakpoints.down('sm')} {
        align-items: center;
        justify-content: center;
        display: flex;
      }
      &-Mobile {
        ${p => p.theme.breakpoints.up('md')} {
          display: none;
        }
      }
      &-Desktop {
        ${p => p.theme.breakpoints.down('sm')} {
          display: none;
        }
      }
    }
  }

  .MyCompanyBody__No-Licenses-Text {
    color: ${p => p.theme.palette.semantic.colorForeground};
    background: ${p => p.theme.palette.semantic.colorBackground};
    border-radius: ${p => p.theme.spacing(1)}px;
    text-align: center;
    padding: ${p => p.theme.spacing(2)}px;
  }

  .MyCompanyBody__License-Column--Details-Text {
    display: flex;
    flex-direction: column;
    .MyCompanyBody__License--Nickname {
      font-size: 1.2rem;
      line-height: 2rem;
      color: ${p => p.theme.palette.semantic.colorForeground};
    }
    .MyCompanyBody__License--Boon-Name {
      color: ${p => p.theme.palette.semantic.colorForeground};
    }
  }

  .MyCompanyBody__License-Column--Mobile-Status {
    display: none;
    ${p => p.theme.breakpoints.down('sm')} {
      display: block;
      margin-left: ${p => p.theme.spacing(2)}px;
    }
    .Label {
      ${p => p.theme.breakpoints.down('sm')} {
        width: 12px;
        height: 12px;
        border-radius: 100%;
      }
      &__Active {
        background: ${p => p.theme.palette.green[500]};
      }
      &__Disabled {
        background: #858f95;
      }
      &__Expired {
        background: ${p => p.theme.palette.error.main};
      }
      &__Pending {
        background: ${p => p.theme.palette.warning[500]};
      }
    }
    .Label__Text {
      display: none;
    }
  }

  .MyCompanyBody__License-Column-Mobile-Title {
    display: none;
    ${p => p.theme.breakpoints.down('sm')} {
      display: block;
      color: #858f95;
      margin-bottom: ${p => p.theme.spacing(1)}px;
      font-size: 1.2rem;
    }
  }

  .MyCompanyBody__License-Expiring-Soon {
    display: flex;
    flex-direction: row;
    align-items: center;
    .MyCompanyBody__License-Expiring-Soon-Icon {
      margin-right: 4px;
      width: 14px;
      height: 14px;
    }
    .MyCompanyBody__License-Expiring-Soon-Text {
      color: #cc3d45;
    }
  }

  .MyCompanyBody__License-With-Note {
    background-image: linear-gradient(
      225deg,
      ${p => p.theme.palette.blue[300]},
      ${p => p.theme.palette.blue[300]} 12px,
      transparent 12px,
      transparent
    );
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
  }

  .MyCompanyBody__License-Card-V2 {
    .MyCompanyBody__License-Card {
      padding: 10px 8px;
      min-height: 80px;
      .MyCompanyBody__License-Column--opener {
        margin-right: 8px;
        width: 24px;
      }
      .MyCompanyBody__License-Column-Serial {
        margin: 0;
        color: ${p => p.theme.palette.semantic.colorForeground};
      }
      .MyCompanyBody__License-Menu {
        .MyCompanyBody__License-Menu-Opener {
          cursor: pointer;
        }
      }
    }
  }

  .MyCompanyBody__License-Card-Outer {
    margin-bottom: ${p => p.theme.spacing(3)}px;
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    border-radius: ${p => p.theme.spacing(1)}px;
  }

  .MyCompanyBody__License-Card-Outer-No-Products {
    .MyCompanyBody__License-Card {
      padding-right: 60px;
    }
  }

  .MyCompanyBody__License-Card {
    width: 100%;
    border-radius: ${p => p.theme.spacing(1)}px;
    padding: ${p => p.theme.spacing(2)}px;
    background: ${p => p.theme.palette.semantic.colorBackground};
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    &:last-child {
      margin-bottom: 0;
    }
    h5,
    .MyCompanyBody__License-Column-Value {
      color: ${p => p.theme.palette.semantic.colorForeground};
    }
  }

  .MyCompanyBody__License-Card-Info {
    padding: 8px 16px 8px 28px;
    ${p => p.theme.breakpoints.down('sm')} {
      padding: 8px 16px;
    }

    .MyCompanyBody__License-Card-Sub-License {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      padding: 16px 0;
    }
    .MyCompanyBody__License-Column--details,
    .MyCompanyBody__License-Column--name {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
    }
    .MyCompanyBody__License-Info-Quantity {
      width: ${LicenseInfoQuantityWidth}px;
      min-width: 24px;
      min-height: 24px;
      height: 24px;
      border-radius: 8px;
      text-align: center;
      display: flex;
      flex-direction: column;
      background: ${p => p.theme.palette.semantic.colorPrimary};
      p {
        font-size: ${LicenseInfoQuantityFontSize}px;
        color: ${p => p.theme.palette.semantic.colorBackground};
        line-height: 2.4rem;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .MyCompanyBody__License-Column--Details-Text {
      margin-left: 16px;
      max-width: 430px;
    }
    .MyCompanyBody__License-Card-Info-Name {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
    }
  }

  .MyCompanyBody__License-Name-Active,
  .MyCompanyBody__License-Name-Inactive {
    position: relative;
    margin-right: 28px;
    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      margin-top: 5px;
      margin-left: 16px;
    }
    ${p => p.theme.breakpoints.up('md')} {
      &:after {
        display: none;
      }
    }
  }

  .MyCompanyBody__License-Name-Active {
    &:after {
      background: ${p => p.theme.palette.success.main};
    }
  }

  .MyCompanyBody__License-Name-Inactive {
    &:after {
      background: ${p => p.theme.palette.error.main};
    }
  }

  .Apollo__Tooltip-Text {
    line-height: 16px;
  }
`;

export const SideSection = styled.div`
  max-width: 282px;
  width: 100%;
  ${p => p.theme.breakpoints.down('sm')} {
    max-width: 800px;
  }
`;

export const MenuOpener = styled.button`
  border: none;
  color: ${p => p.theme.palette.semantic.colorForeground};
  background: none !important;

  &:hover {
    cursor: pointer;
  }
`;

export const SelectOption = styled.div`
  button {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
  .ExpandOptions__Button {
    padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
    cursor: pointer;
    outline: none;
    border: 0;
    background-color: inherit;
    font-size: 1.4rem;
    font-weight: 400 !important;
    line-height: 1.7;
    width: 100%;
    text-align: left;

    &:hover {
      background: ${p => p.theme.palette.semantic.colorBackgroundHover};
    }
  }
`;

export const OptionsPopover = styled(Popover)`
  cursor: pointer;

  .MuiPopover-paper {
    background-color: ${p => p.theme.palette.semantic.colorBackgroundRaised};
  }
`;

export const LicenseCardMenu = styled(Menu)`
  position: relative;
  .MuiMenu-paper {
    border-radius: 8px;
    margin-top: 12px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.14),
      0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
  }
  .UserCard__Menu-Option {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${p => p.theme.palette.semantic.colorForeground};
    padding: 6px 16px;
  }
  .MuiList-root {
    padding: 0;
  }
`;

export const SmallTooltipTypography = styled.p`
  font-size: 12px;
  line-height: 16px;
`;

export const NameModalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
  .AddCustomLicenseNameModal__Ticket-Field {
    width: 100%;
    .AddCustomLicenseNameModal__Ticket-Field--Textarea {
      color: ${p => p.theme.palette.semantic.colorForeground};
      background: ${p => p.theme.palette.semantic.colorBackground};
      border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    }
  }
  .AddCustomLicenseNameModal__Ticket-Field--SelectWithSearch {
    width: 50%;
    .Custom-Select__control {
      border-radius: 8px;
      border: 1px solid ${p => p.theme.palette.grey[400]};
    }
  }
  .AddCustomLicenseNameModal__Form-Button {
    margin-top: 32px;
    width: 138px;
    color: ${p => p.theme.palette.semantic.colorBackground};
    background: ${p => p.theme.palette.semantic.colorPrimary};
  }
`;

export const HAPO = styled.div`

  .MyCompanyBody__SubHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${p => p.theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  }

  Button {
    border-radius: ${p => p.theme.spacing(1)}px;
    outline: none;
    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${p => p.theme.palette.semantic.colorBackground};

    ${p => p.theme.breakpoints.up('sm')} {
      margin-left: ${p => p.theme.spacing(2)}px;
    }
  }

  .HAPO__AuditHistoryButton {
    background: transparent;
    color: ${p => p.theme.palette.semantic.colorPrimary}
  }

  .HAPO__License-Column {
    &--details,
    &--total,
    &--used,
    &--available,
    &--progressBar {
      ${p => p.theme.breakpoints.down('sm')} {
        display: none;
      }
    }
  }

  .HAPO__License-Table-Column-Title {
    padding: ${p => p.theme.spacing(2)}px 40px ${p => p.theme.spacing(2)}px ;
  }

  .HAPO__License-Column--details {
    flex: 5;
  }
  .HAPO__License-Column--progressBar {
    flex: 2;
  }
  .HAPO__License-Column {
    &--total,
    &--used,
    &--available {
      flex: 1;
      text-align: center;
    }

    &__infoIcon {
      cursor: pointer;
    }
  }
`;

export const CountsChip = styled(Chip)`
  margin: 0px 4px 8px 4px !important;
  max-width: 50% !important;
  border-color: ${p => p.theme.palette.semantic.colorBorder} !important;
  cursor: pointer;

  span.MuiChip-label {
    color: ${p => p.theme.palette.common.white};
    font-size: 14px;
  }
`;

export const EntityManagementModalInner = styled.div`
  margin-top: ${p => p.theme.spacing(2)}px;

  .AddCustomLicenseNameModal__Ticket-Field {
    width: 100%;
  }

  .FormControl {
    width: 100%;

    .Input-Label {
      margin-bottom: ${p => p.theme.spacing(1)}px;
    }

    .TextField {
      width: 40%;
      .MuiOutlinedInput-root {
      & fieldset {
        border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
      }
      &:hover fieldset {
        border: 2px solid ${p => p.theme.palette.primary.main};
      }
      &.Mui-focused fieldset {
          border: 2px solid ${p => p.theme.palette.primary.main};
        }
      }
    }

    .SelectWithSearch {
      width: 40%;
      border-radius: 8px;
      border: 1px solid ${p => p.theme.palette.grey[400]};
    }

    .Button {
      color: ${p => p.theme.palette.semantic.colorBackground};
      width: 25%;
    }

    p {
      color: ${p => p.theme.palette.semantic.colorForeground};
    }
  }

  .ValidationErrorMessage {
    color: ${p => p.theme.palette.error.main};
    display: block;
    margin-top: 12px;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .Button {
    margin-top: ${p => p.theme.spacing(2)}px;
  }
`;
