import styled from 'styled-components';

import defaultHeroBg from '../../img/Hero-Header.png';
import { ContentWrapper } from '../CustomerPortalContentWrapper';

export const CloudMigrationContainer = styled.div`
  background-color: ${p => p.theme.palette.semantic.colorBackground} !important;
  background: url(${defaultHeroBg});
  background-repeat: no-repeat;
  background-size: contain;
  padding: 6% 28% 0%;
  z-index: 0;
  ${ContentWrapper}

  .CloudMigration__Heading-Text {
    color: ${p => p.theme.palette.semantic.colorForeground};
    font-size: 2.8rem;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1.14;
    margin-bottom: 5%;
    text-align: center;
  }

  .CloudMigration__Text {
    color: ${p => p.theme.palette.semantic.colorForeground};
    font-weight: 200;
    font-size: 18px;
    line-height: 28px;
    white-space: pre-wrap;
    b {
      font-weight: 600;
    }
    ul {
      margin-top: 8px !important;
    }
    li {
      margin-bottom: 8px;
    }
  }

  .CloudMigration__User-List {
    line-height: 16px;
    white-space: pre-wrap;
    margin-top: 16px;
    margin-left: 16px;

    .MuiSvgIcon-root {
      font-size: 20px;
    }

    .CloudMigration__Select-All-Invite-Checkbox {
      .MuiFormControlLabel-label {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .CloudMigration__Invite-Checkbox {
      font-size: 14px;
    }

    .CloudMigration__Invite-Checkbox-Label {
      display: flex;
      justify-content: space-between;
      width: 450px;
      align-items: center;
      gap: 16px;
    }
  }

  .CloudMigration__Bolded-Text {
    color: ${p => p.theme.palette.semantic.colorForeground};
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    white-space: pre-wrap;
    margin-top: 32px;
  }

  .CloudMigration__Button-Container {
    display: flex;
    flex-direction: column;
    margin: 8% 24% 0;
    .CloudMigration__Button {
      width: 100%;
      margin-bottom: 28px;
      font-size: 16px !important;
      padding: 10px 16px;
      background-color: ${p => p.theme.palette.semantic.colorPrimary};
      color: ${p => p.theme.palette.semantic.colorForegroundInverse};
      &:hover {
        background-color: ${p => p.theme.palette.semantic.colorPrimaryHover};
        opacity: 0.9;
    }
  }
`;

