export enum IncidentStatus {
  INVESTIGATING = 'investigating',
  IDENTIFIED = 'identified',
  MONITORING = 'monitoring',
  UPDATE = 'update',
  RESOLVED = 'resolved',
}

export const INCIDENT_STATUS_IN_PROGRESS = [
  IncidentStatus.INVESTIGATING,
  IncidentStatus.IDENTIFIED,
  IncidentStatus.MONITORING,
  IncidentStatus.UPDATE,
];

export const INCIDENT_STATUS_RESOLVED = [ IncidentStatus.RESOLVED ];

export enum IncidentImpact {
  NONE = 'none',
  MAINTENANCE = 'maintenance',
  MINOR = 'minor',
  MAJOR = 'major',
  CRITICAL = 'critical',
}

export const IncidentImpactSeverityOrder: Record<string, number> = {
  'critical': 0,
  'major': 1,
  'minor': 2,
  'maintenance': 3,
  'none': 4,
};

export const INCIDENT_LIMIT_COUNT = 10;
