import styled from 'styled-components';

export const Container = styled.div`
  margin: ${p => p.theme.spacing(2.5)}px 0;

  font-size: 1.4rem;
  line-height: 24px;

  .MuiIconButton-root.MuiIconButton-root {
    transform: scale(1.5);
    align-self: flex-start;

    &+span {
      padding-top: ${p => p.theme.spacing(0.75)}px;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .CustomerPortalActivateAccount__formField {
    &--Success-Color {
      color: green;
    }

    &--Error-Color {
      color: red;
    }
  }

  h3 {
    margin-bottom: ${p => p.theme.spacing(2)}px;
    padding: ${p => p.theme.spacing(2)}px;

    border-radius: 4px;
    background-color: ${p => p.theme.palette.primary.main};
    box-shadow: 2px 2px 2px ${p => p.theme.palette.ink[200]};

    text-align: center;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  p {
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }

  .ActivateAdminUserModal__checkboxWrapper {
    padding: ${p => p.theme.spacing(2)}px;
    border: 1px solid;
    border-radius: 4px;
    background-color: ${p => p.theme.palette.ink[200]};
    box-shadow: 2px 2px 2px ${p => p.theme.palette.ink[200]};
  }

  .ActivateAdminUserModal__Divider {
    margin: ${p => p.theme.spacing(2.5)}px 0;

    display: flex;
    align-items: center;
    text-align: center;

    /* Show the lines on right
    and left sides of the text */
    &:after, &:before {
      content: "";
      border: 1px solid #999;
      flex: 1;
    }

    /* Space on left and right sides of text */
    &:not(:empty)::before {
      margin-right: 0.25em;
    }

    &:not(:empty)::after {
      margin-left: 0.25em;
    }
  }

  .ActivateAdminUserModal__EmailContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .ActivateAdminUserModal__EmailField {
      width: 330px;
      border: 1px solid #9e9e9e;
      border-radius: 6px;
    }

    input.MuiInputBase-input {
      border: none !important;
    }
  }

  .ActivateAdminUserModal__ApiErrorContainer {
    margin-top: ${p => p.theme.spacing(2)}px;

    border-radius: 4px;
    background-color: ${p => p.theme.palette.primary.light};
    box-shadow: 2px 2px 2px ${p => p.theme.palette.ink[200]};

    p {
      padding: ${p => p.theme.spacing(2)}px;
      font-weight: bold;
    }
  }

  .ActivateAdminUserModal__ButtonsContainer {
    margin-top: ${p => p.theme.spacing(5)}px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .ActivateAdminUserModal__field {
      width: 150px;
      margin-right: ${p => p.theme.spacing(4)}px;

      button.ActivateAdminUserModal__ActivateAdminUserButton {
        font-size: 16px !important;
        padding: 10px 16px !important;
      }
      button.ActivateAdminUserModal__AskMeLaterButton {
        background: none;
        color: ${p => p.theme.palette.semantic.colorPrimary};
        font-size: 16px !important;
      }
    }
  }
`;

export const ValidationErrorMessage = styled.span`
  color: ${p => p.theme.palette.error.main};
  display: block;
  margin-top: 12px;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const Spinner = styled.div`
  margin: ${p => p.theme.spacing(5)}px 0;

  svg {
    display: block;
    margin: 0 auto;
    width: ${p => p.theme.spacing(12.5)}px;
    height: ${p => p.theme.spacing(12.5)}px;
  }
`;
