import type {
  FacetProps,
  FacetValue,
  SearchEngine,
} from '@coveo/headless';
import {
  buildFacet,
  buildQuerySummary,
} from '@coveo/headless';
import { DocsGptResponseSource } from '@customer-portal/constants';
import {
  Button,
  FormControlLabel,
  Switch,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import AutopilotIcon from '../../../assets/img/Autopilot-Icon.svg';
import { axiosPost } from '../../../client/axios';
import DocsGptDeflection from '../../../components/support/NewSupportCaseBody/DocsGptDeflection';
import { DeliveryOptionsWithVersionSelections } from '../../../constants/caseAssist.constants';
import { SUPPORT_GPT_SEARCH_URL } from '../../../constants/network.constants';
import { useAuth } from '../../../contexts/auth';
import { StoreContext } from '../../../store';
import {
  getDeliveryOptionsLists,
  getGroupedDeliveryOptions,
  pushNewHashToUrl,
} from '../../../utils/search';
import { DEFAULT_FACET_PROPS } from '../Facets/FacetFilter/FacetFilter';
import { FacetFilterTypes } from '../Facets/types';

const Container = styled.div`
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: rgba(17, 17, 17, 0.24) 0.25rem 0.25rem 0.5rem;
`;

const AutopilotTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RegenerateButton = styled(Button)`
  margin-right: 18px !important;

  &:disabled {
    color: ${p => p.theme.palette.grey[600]} !important;
    background-color: ${p => p.theme.palette.grey[200]} !important;
    border: none !important;
  }
`;

const AutopilotTitle = styled.p`
  font-size: 1.5rem;
  color: ${p => p.theme.palette.semantic.colorForeground};

  img {
    margin-right: 8px;
  }
`;

const AutopilotText = styled.p`
  font-weight: 600;
  margin-bottom: 14px;
  color: ${p => p.theme.palette.semantic.colorForeground};
`;

const FilterContainer = styled.div`
  margin-top: 14px;
  max-width: 90%;
`;

const FilterPrompt = styled.p`
  margin-top: 10px;
  color: ${p => p.theme.palette.semantic.colorForeground};
`;

const FilterButton = styled(Button)`
  padding: 6px 12px !important;
  margin-right: 10px !important;
  margin-top: 10px !important;
  border-radius: 16px !important;
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder} !important;
  color: ${p => p.theme.palette.semantic.colorForeground} !important;
  transition: background-color 0.2s ease-out !important;
  font-weight: 400 !important;

  &.selected {
    background: ${p => p.theme.palette.info.main} !important;
    color: ${p => p.theme.palette.semantic.colorBackground} !important;
    border: none !important;
  }
`;

const ResponseContainer = styled.div`
  margin-top: 16px;
`;

const ErrorContainer = styled.div`
  padding: 12px;
  font-size: 1.4rem;
  line-height: 1.5;
  color: ${p => p.theme.palette.error.main};
  border: 1px solid ${p => p.theme.palette.error.main};
  background-color: ${p => p.theme.palette.semantic.colorErrorBackground};
`;

const FormControlLabelStyled = styled(FormControlLabel)`
    color: ${p => p.theme.palette.semantic.colorForeground};
 `;

interface Props {
  engine: SearchEngine;
  showRegenerateButton: boolean;
}

const DocsGptSection = (props: Props) => {
  const { t } = useTranslation('common');
  const {
    engine, showRegenerateButton,
  } = props;
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();

  const [ showAutopilot, setShowAutopilot ] = useState(true);
  const [ gptResponse, setGptResponse ] = useState('');
  const [ isGptResponseLoading, setIsGptResponseLoading ] = useState(false);
  const [ receivedFeedbackResponse, setReceivedFeedbackResponse ] = useState(false);
  const [ isAnswerHelpful, setIsAnswerHelpful ] = useState(false);
  const [ doneStreamedText, setDoneStreamedText ] = useState(false);
  const [ filterSelected, setFilterSelected ] = useState(false);
  const [ error, setError ] = useState('');
  const [ enableRegenerateResponse, setEnableRegenerateResponse ] = useState(false);
  const [ deliveryOptionsList, setDeliveryOptionsList ] = useState<FacetValue[]>([]);
  const [ selectedDeliveryOption, setSelectedDeliveryOption ] = useState<any>(null);
  const [ groupedDeliveryOptions, setGroupedDeliveryOptions ] = useState<Record<string, {
    numberOfResults: number;
    allValuesForGroup: string[];
  }>>({});
  const [ selectedFilters, setSelectedFilters ] = useState<any>({});

  const querySummaryControllerRef = useRef(buildQuerySummary(engine));
  const [ querySummaryState, setQuerySummaryState ] = useState(
    querySummaryControllerRef.current.state
  );

  // Delivery Option Facet
  const finalDeliveryOptionProps: FacetProps = useMemo(() => {
    const initialProps = DEFAULT_FACET_PROPS;

    return {
      ...initialProps,
      options: {
        ...initialProps.options,
        field: 'delivery_option',
        facetId: 'delivery_option',
      },
    };
  }, []);
  const deliveryOptionControllerRef = useRef(buildFacet(engine, finalDeliveryOptionProps));
  const [ deliveryOptionControllerState, setDeliveryOptionControllerState ] = useState(
    deliveryOptionControllerRef.current.state
  );

  // Product Facet
  const finalProductProps: FacetProps = useMemo(() => {
    const initialProps = DEFAULT_FACET_PROPS;

    return {
      ...initialProps,
      options: {
        ...initialProps.options,
        field: 'product',
        facetId: 'product',
      },
    };
  }, []);
  const productControllerRef = useRef(buildFacet(engine, finalProductProps));
  const [ productControllerState, setProductControllerState ] = useState(
    productControllerRef.current.state
  );

  // Version Facet
  const finalVersionProps: FacetProps = useMemo(() => {
    const initialProps = DEFAULT_FACET_PROPS;

    return {
      ...initialProps,
      options: {
        ...initialProps.options,
        field: 'version_hierarchy',
        facetId: 'version_hierarchy',
      },
    };
  }, []);
  const versionControllerRef = useRef(buildFacet(engine, finalVersionProps));
  const [ versionControllerState, setVersionControllerState ] = useState(
    versionControllerRef.current.state
  );

  // handle query summary controller state changes
  const setAndSubscribeController = useCallback(() => {
    const unsubscribeController = querySummaryControllerRef.current.subscribe(
      () => {
        setQuerySummaryState(querySummaryControllerRef.current.state);
      }
    );

    return () => {
      unsubscribeController();
    };
  }, []);

  useEffect(setAndSubscribeController, [ setAndSubscribeController ]);

  // handle facet controller state changes
  useEffect(() => {
    const unsubscribeDeliveryOption = deliveryOptionControllerRef.current.subscribe(() => {
      setDeliveryOptionControllerState(deliveryOptionControllerRef.current.state);
    });

    const unsubscribeProduct = productControllerRef.current.subscribe(() => {
      setProductControllerState(productControllerRef.current.state);
    });

    const unsubscribeVersion = versionControllerRef.current.subscribe(() => {
      setVersionControllerState(versionControllerRef.current.state);
    });

    return () => {
      unsubscribeDeliveryOption();
      unsubscribeProduct();
      unsubscribeVersion();
    };
  }, []);

  const toggleFacet = (facetId: String, facetValue: any) => {
    if (!facetValue) {
      return;
    }

    const updatedURLSearchParams = new URLSearchParams(
      window.location.hash.slice(1)
    );
    updatedURLSearchParams.delete(`f-${facetId}`);

    const allActiveFacets: Set<string> = new Set();

    if (facetId === FacetFilterTypes.DELIVERY_OPTION) {
      const allDeliveryOptionsForSelection = groupedDeliveryOptions[facetValue.value]?.allValuesForGroup ?? [];
      allDeliveryOptionsForSelection.forEach((deliveryOption) => {
        allActiveFacets.add(deliveryOption);
      });
    } else {
      allActiveFacets.add(facetValue.value);
    }

    // Update the URL Params by using the active delivery option
    allActiveFacets.size
      ? updatedURLSearchParams.set(
        `f-${facetId}`,
        Array.from(allActiveFacets).map(encodeURIComponent)
          .join(',')
      )
      : updatedURLSearchParams.delete(`f-${facetId}`);

    // Create new query hash from URL Params
    const updatedURLSearchParamsText = Array.from(updatedURLSearchParams.entries())
      .map(([ key, val ]) => `${key}=${val}`)
      .join('&');

    pushNewHashToUrl(updatedURLSearchParamsText);
  };

  const getDocsGptResponse = async (deliveryOption: any = null) => {
    toggleFacet('delivery_option', deliveryOption);
    setError('');
    setEnableRegenerateResponse(false);
    setFilterSelected(true);
    setShowAutopilot(true);
    setIsGptResponseLoading(true);
    setReceivedFeedbackResponse(false);
    setDoneStreamedText(false);
    setSelectedDeliveryOption(null);
    setSelectedFilters({});
    try {

      const updatedURLSearchParams = new URLSearchParams(
        window.location.hash.slice(1)
      );

      // get selected delivery option
      const allActiveDeliveryOption = Array.from(new Set(
        updatedURLSearchParams.get('f-delivery_option')?.split(',')
      ));

      // get selected product
      const allActiveProduct = Array.from(new Set(
        updatedURLSearchParams.get('f-product')?.split(',')
      ));

      // get selected version
      const allActiveVersion = Array.from(new Set(
        updatedURLSearchParams.get('f-version_hierarchy')?.split(',')
      ));
      const allActiveVersionUpdated = allActiveVersion.map((version: string) =>
        version.split('|').join('.')
      );

      if (deliveryOptionsList?.length !== 0) {
        if (allActiveDeliveryOption?.length === 0) {
          setFilterSelected(false);
          resetFilters();
          return;
        } else if ((allActiveDeliveryOption.includes('Automation Suite') || allActiveDeliveryOption.includes('Standalone')) &&
          allActiveVersion?.length === 0 && versionControllerState?.values?.length !== 0) {
          setFilterSelected(false);
          setSelectedDeliveryOption({
            value: allActiveDeliveryOption.filter((option) => !option.includes(';'))[0],
            state: 'idle',
          });
          return;
        }
      }

      const docsgptResult = await axiosPost(
        SUPPORT_GPT_SEARCH_URL,
        state.companyId,
        await getAccessToken(),
        {
          question: querySummaryState.query,
          products: allActiveProduct,
          deliveryOptions: allActiveDeliveryOption,
          versions: allActiveVersionUpdated,
          source: DocsGptResponseSource.SEARCH,
        },
      );

      if (docsgptResult.status === 200) {
        setGptResponse(docsgptResult.data);
        setSelectedFilters({
          deliveryOption: allActiveDeliveryOption,
          product: allActiveProduct,
          version: allActiveVersionUpdated,
        });
      }
    } catch (e) {
      if (e.response?.data?.statusCode === 429) {
        setError(t('smart_search_too_many_requests_error', 'Thank you for using Smart Search! Due to high demand, this feature needs a brief pause. You can continue exploring the search results below to find answers to your query. We appreciate your understanding and patience.'));
      } else {
        setError(t('smart_search_something_went_wrong_error', 'Something went wrong, please try again later.'));
      }
    }
    setIsGptResponseLoading(false);
  };

  const onDeliveryOptionSelect = (deliveryOption: any) => {
    setSelectedDeliveryOption(deliveryOption);
    if (!DeliveryOptionsWithVersionSelections.includes(deliveryOption.value)) {
      getDocsGptResponse(deliveryOption);
    }
  };

  const onVersionSelect = (version: any) => {
    toggleFacet('version_hierarchy', version);
    getDocsGptResponse(selectedDeliveryOption);
  };

  const onRegenerateResponse = () => {
    getDocsGptResponse();
    setEnableRegenerateResponse(false);
  };

  const resetFilters = () => {
    const updatedURLSearchParams = new URLSearchParams(
      window.location.hash.slice(1)
    );
    updatedURLSearchParams.delete(`f-delivery_option`);
    updatedURLSearchParams.delete(`f-product`);
    updatedURLSearchParams.delete(`f-version_hierarchy`);
    const updatedURLSearchParamsText = Array.from(updatedURLSearchParams.entries())
      .map(([ key, val ]) => `${key}=${val}`)
      .join('&');
    pushNewHashToUrl(updatedURLSearchParamsText);
  };

  useEffect(() => {
    setFilterSelected(false);
    resetFilters();
  }, [ querySummaryState.query ]);

  useEffect(() => {
    setEnableRegenerateResponse(filterSelected && gptResponse !== '' && !isGptResponseLoading);
  }, [
    deliveryOptionControllerState.values,
    productControllerState.values,
    versionControllerState.values,
  ]);

  useEffect(() => {
    const deliveryOptionsListRes = getDeliveryOptionsLists(deliveryOptionControllerState.values);
    setDeliveryOptionsList(deliveryOptionsListRes);
    setGroupedDeliveryOptions(getGroupedDeliveryOptions(deliveryOptionControllerState.values));
    if (querySummaryState?.query && deliveryOptionsListRes?.length === 0) {
      setFilterSelected(true);
      getDocsGptResponse();
    }
  }, [
    querySummaryState.query,
    deliveryOptionControllerState.values,
    productControllerState.values,
    versionControllerState.values,
  ]);

  return (
    <div>
      {
        querySummaryState.query !== '' && (
          <Container>
            <AutopilotTitleContainer>
              <AutopilotTitle>
                <img src={AutopilotIcon} />
                {filterSelected ? t('smart_search_says', 'Smart Search Says:') : t('smart_search_ask', 'Ask Smart Search:')}
              </AutopilotTitle>
              <div>
                {showRegenerateButton && <RegenerateButton
                  disabled={!enableRegenerateResponse}
                  variant='outlined'
                  onClick={onRegenerateResponse}
                >
                  {t('smart_search_regenerate_response', 'Regenerate response')}
                </RegenerateButton>}
                <FormControlLabelStyled
                  control={
                    <Switch
                      checked={showAutopilot}
                      onChange={() => setShowAutopilot(!showAutopilot)} />
                  }
                  label={showAutopilot ? t('smart_search_switch_hide', 'Hide') : t('smart_search_switch_show', 'Show')}
                />
              </div>
            </AutopilotTitleContainer>
            {showAutopilot && !filterSelected && (
              <div>
                <AutopilotText>{querySummaryState.query}</AutopilotText>
                <hr />
                <FilterContainer>
                  <FilterPrompt>{t('smart_search_delivery_option_filter_prompt', 'Please select delivery option:')}</FilterPrompt>
                  {deliveryOptionsList.map((option, index) => (
                    <FilterButton
                      className={`${selectedDeliveryOption?.value === option.value ? 'selected' : ''}`}
                      key={index}
                      onClick={() => onDeliveryOptionSelect(option)}>
                      {option.value}
                    </FilterButton>
                  ))}
                  {
                    selectedDeliveryOption?.value && versionControllerState?.values?.length > 0 && (
                      <div>
                        <FilterPrompt>{t('smart_search_version_filter_prompt', 'Please select version:')}</FilterPrompt>
                        {versionControllerState.values.map((option, index) => (
                          <>
                            {option.value.split('|')?.length === 2 && !option.value.includes('deprecated') && (
                              <FilterButton
                                onClick={() => onVersionSelect(option)}>
                                {option.value.split('|').join('.')}
                              </FilterButton>
                            )}
                          </>
                        ))}
                      </div>
                    )
                  }
                </FilterContainer>
              </div>
            )}
            <ResponseContainer>
              {showAutopilot && filterSelected && (
                error !== '' ? (
                  <ErrorContainer>
                    {error}
                  </ErrorContainer>
                ) : (
                  <DocsGptDeflection
                    gptResponse={gptResponse}
                    isGptResponseLoading={isGptResponseLoading}
                    receivedFeedbackResponse={receivedFeedbackResponse}
                    setReceivedFeedbackResponse={setReceivedFeedbackResponse}
                    isAnswerHelpful={isAnswerHelpful}
                    setIsAnswerHelpful={setIsAnswerHelpful}
                    doneStreamedText={doneStreamedText}
                    setDoneStreamedText={setDoneStreamedText}
                    query={querySummaryState.query}
                    selectedFilters={selectedFilters}
                    inSearchPage
                  />
                )
              )}
            </ResponseContainer>
          </Container>
        )
      }
    </div>
  );
};

export default DocsGptSection;
