import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import {
  apolloMaterialUiThemeDark,
  apolloMaterialUiThemeLight,
} from '@uipath/apollo-material-ui-theme';
import type { ApolloThemeType } from '@uipath/portal-shell-util';
import {
  getApolloThemeFromTheme,
  getCachedTheme,
} from '@uipath/portal-shell-util';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { ThemeName } from '../../constants/theme.constants';

type ThemeChangedEvent = Event & {
  detail: {
    selectedThemeId: ApolloThemeType;
  };
};

const getApolloTheme = (name: ApolloThemeType) => {
  switch (name) {
    case ThemeName.DARK:
      return apolloMaterialUiThemeDark;
    case ThemeName.LIGHT:
    case ThemeName.LIGHT_HC:
    default:
      return apolloMaterialUiThemeLight;
  }
};

interface StyledThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = (props: StyledThemeProviderProps) => {
  // Do not render content until theme has been set
  const [ renderContent, setRenderContent ] = useState<boolean>(false);
  const [ themeId, setThemeId ] = useState<ApolloThemeType>(ThemeName.LIGHT);

  const apolloTheme = useMemo(() => getApolloTheme(themeId), [ themeId ]);

  useEffect(() => {
    // The below will look for local storage key "PORTAL_THEME" (set through cloud preferences)
    // If not defined (public site) it will default to system theme (light theme as default if not able to determine system theme)
    const cachedSelectedTheme = getCachedTheme();
    const selectedThemeId = getApolloThemeFromTheme(cachedSelectedTheme);
    setThemeId(selectedThemeId);

    const root = document.documentElement;
    if (selectedThemeId === 'dark') {
      root.classList.add('dark-theme');
    } else {
      root.classList.remove('dark-theme');
    }

    // Listen to portal-shell theme change event, fires on theme change and on page load
    document.addEventListener('themeChanged', (event) => {
      const themeChangedEvent = event as ThemeChangedEvent;
      setThemeId(themeChangedEvent.detail.selectedThemeId);
    });

    setRenderContent(true);
  }, []);

  return renderContent ? (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={apolloTheme}>
        <StyledComponentsThemeProvider theme={apolloTheme}>
          {props.children}
        </StyledComponentsThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  ) : null;
};
