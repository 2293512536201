import {
  FormControl,
  FormHelperText,
  InputLabel,
  Switch,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { axiosPatch } from '../../client/axios';
import {
  CLOUD_DOMAIN,
  SERVICE_REDIRECT_KEY,
  SERVICE_REDIRECT_URI_KEY,
  SERVICE_REDIRECT_VALUE,
} from '../../constants/auth.constants';
import { UPDATE_COMPANY_SETTINGS_URL } from '../../constants/network.constants';
import { CLOUD_CONNECT_THROUGH_ACCOUNT_SETTINGS } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import { useTriggerTrackEventWithStateData } from '../../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
import { featureFlag } from '../../utils/featureFlag';
import { clearSession } from '../../utils/session';

const Container = styled.div`
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  color: ${p => p.theme.palette.semantic.colorForeground};
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  border-radius: ${p => p.theme.spacing(1)}px;
  padding: ${p => p.theme.spacing(2)}px;
  margin: ${p => p.theme.spacing(2)}px 0px;
  display: flex;
  flex-direction: column;


  .MuiInputLabel-root {
    color: ${p => p.theme.palette.semantic.colorForeground};
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2rem;
    position: unset;
    transform: none;
  }

  .MuiFormHelperText-root {
    color: ${p => p.theme.palette.semantic.colorForegroundDeEmp};
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6rem;
  }

  .CloudMigrationForm {
    margin-top: ${p => p.theme.spacing(4)}px;
  }
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: ${p => p.theme.spacing(2)}px;
`;

const CompanyAccessSettings = () => {
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { t } = useTranslation('common');
  const { getAccessToken } = useAuth();
  const [ isCloudEnabled, setIsCloudEnabled ] = useState(true);

  const isEditCompanySettingsAllowed = UserPermissionsHelper.isEditCompanySettingsAllowed();
  const isMoveToCloudAllowed = UserPermissionsHelper.isMoveToCloudAllowed();
  const isCompanyOnCloud = !!(state.currentUserCompany?.additionalCompanyInfo?.cloudOrgId);
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  const updateCompanySettingsInvite = async (
    _e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const isSelfRegistrationEnabled = !checked;

    try {
      const res = await axiosPatch(
        UPDATE_COMPANY_SETTINGS_URL,
        state.companyId,
        await getAccessToken(),
        { updateObject: { isSelfRegistrationEnabled } }
      );

      if (res.status === 201) {
        dispatch({
          type: 'setIsSelfRegistrationEnabled',
          payload: isSelfRegistrationEnabled,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onConnectToCloud = async (
    _e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      triggerTrackEventWithStateData(CLOUD_CONNECT_THROUGH_ACCOUNT_SETTINGS, {});
      clearSession();
      window.location.href = `${CLOUD_DOMAIN}/portal_/cloudrpa?${SERVICE_REDIRECT_KEY}=${SERVICE_REDIRECT_VALUE}&${SERVICE_REDIRECT_URI_KEY}=/cloud-migration`;
    }
  };

  const updateCloudEnabled = async () => {
    try {
      const { companyId } = state;

      const isCloudFeatureEnabled = await featureFlag.isCloudEnabled(
        companyId,
        await getAccessToken()
      );

      setIsCloudEnabled(isCloudFeatureEnabled);
    } catch (e) {
      setIsCloudEnabled(false);
    }
  };

  useEffect(() => {
    updateCloudEnabled();
  }, [ state.companyId ]);

  return (
    <Container>
      <Title>
        {t('company_access_settings_title', 'Account Access Settings')}
      </Title>
      <FormControl>
        <InputLabel htmlFor="">
          {t('company_access_settings_invite_toggle_label', 'Restrict access to invite only')}
        </InputLabel>
        <Switch
          checked={!state.isSelfRegistrationEnabled}
          onChange={updateCompanySettingsInvite}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          disabled={!isEditCompanySettingsAllowed}
          data-testid="company-access-settings-invite-switch"
        />
        <FormHelperText>
          {t(
            'company_access_settings_invite_toggle_helper_text',
            'When the switch is on, users will not be able to manually register for this account.'
          )}
        </FormHelperText>
      </FormControl>

      {isCloudEnabled && (
        <FormControl className='CloudMigrationForm'>
          <InputLabel htmlFor="">
            {t('company_access_settings_cloud_toggle_label', 'Connect to Automation Cloud')}
          </InputLabel>
          <Switch
            checked={isCompanyOnCloud}
            onChange={onConnectToCloud}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            disabled={isCompanyOnCloud || !isMoveToCloudAllowed}
            data-testid="company-access-settings-cloud-switch"
          />
          <FormHelperText>
            {t(
              'company_access_settings_cloud_toggle_helper_text',
              'Connect Customer Portal to your UiPath Automation Cloud. Manage all your users from a single location and have a more streamlined experience.'
            )}
          </FormHelperText>
        </FormControl>
      )}
    </Container>
  );
};

export default CompanyAccessSettings;
