import { Header } from '@customer-portal/constants';
import { Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

// styles
import * as analyticyStyled from '../../assets/css/CustomerPortalAnalytics';
import { CPTableContainer } from '../../assets/css/Table';
// Images
import ErrorIcon from '../../assets/img/svg/status_icons/error.svg';
import WarningIcon from '../../assets/img/svg/status_icons/info.svg';
import { axiosGet } from '../../client/axios';
import AdminAddModal from '../../components/admin/product-catalog/Admin-AddModal';
import AdminEditModal from '../../components/admin/product-catalog/Admin-EditModal';
// components
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
import Button from '../../components/Button/Button';
import ConfirmationModal from '../../components/ConfirmationModal';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
import Container from '../../components/CustomerPortal-New-Container';
import CustomerPortalPagination from '../../components/CustomerPortal-Pagination';
import CustomerPortalSearchInput from '../../components/CustomerPortal-SearchInput';
import SelectWithSearch from '../../components/Select-With-Search';
import {
  RFQ_MAPPING_CHECK_URL,
  RFQ_PRODUCTS_URL,
} from '../../constants/network.constants';
import { PRODUCT_CATALOG_MANAGEMENT } from '../../constants/telemetry.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import {
  canShowLicense,
  canShowLicenseStr,
} from '../../lib/requestForQuote.utils';
import { StoreContext } from '../../store/index';
import type { SkuTypeTransformedAdmin } from '../../types/requestForQuote.types';
import { UserPermissionsHelper } from './../../lib/UserPermissions';

const ProductCatalogWrapper = styled.div`
  margin-bottom: 80px;

  .CustomerPortalProductCatalog__searchInputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    max-width: 100%;
  }

  .CustomerPortalProductCatalog__searchActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }

  .CustomerPortalProductCatalog__filter {
    min-width: 180px;
  }

  .CustomerPortalProductCatalog__editSkuBtn,
  .CustomerPortalProductCatalog__editSkuStatusBtn {
    margin-bottom: 10px;
    padding: 8px 24px;
  }

  .CustomerPortalProductCatalog__editSkuBtn {
    background: #358adf;
  }

  .CustomerPortalProductCatalog__editSkuStatusBtn--inactivate {
    background: #f5222d;
  }

  .CustomerPortalProductCatalog__editSkuStatusBtn--reactivate {
    background: #52c41a;
  }

  .MuiTableCell-head {
    padding: 20px;
    min-width: 400px;
    font-size: 14px;
    font-weight: bold;
  }

  .CustomerPortalProductCatalog__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .CustomerPortalProductCatalog__skuTitle {
    padding: 4px 0;
    font-size: 16px;
  }

  .CustomerPortalProductCatalog__errorIcon {
    padding: 4px 0;
    margin-right: 16px;
    width: 16px;
    height: 16px;
    align-self: center;
  }

  .CustomerPortalProductCatalog__skuId {
    padding-bottom: 16px;
    font-size: 12px;
  }

  .CustomerPortalProductCatalog__skuLongDescriptionTitle {
    padding-bottom: 8px;
    font-size: 12px;
    font-weight: 600;
  }

  .CustomerPortalProductCatalog__skuLongDescription,
  .CustomerPortalProductCatalog__skuLongDescription p {
    font-size: 12px;
  }

  .CustomerPortalProductCatalog__skuShortDescriptionTitle {
    padding-bottom: 8px;
    font-size: 12px;
    font-weight: 600;
  }

  .CustomerPortalProductCatalog__skuShortDescription p {
    padding-bottom: 16px;
    font-size: 12px;
  }

  .CustomerPortalProductCatalog__pagination {
    margin-top: 20px;
    margin-left: 20px;
  }

  .MuiTableCell-root {
    border-color: #aaacaf;
  }

  .MuiChip-root {
    margin: 12px 6px;
    background-color: #a4b1b8;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  .MuiChip-root:hover {
    margin: 12px 6px;
    background-color: #a4b1b8;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  .MuiChip-label {
    font-size: 14px;
    padding: 4px 16px;
  }

  .CustomerPortalProductCatalog__ShowInChip {
    background-color: ${p => p.theme.palette.success.light};
    text-transform: uppercase;
  }

  .CustomerPortalProductCatalog__addNewSkuBtn {
    font-size: 16px !important;
    color: ${p => p.theme.palette.semantic.colorBackground};
  }
`;

const headCells = [
  {
    id: 'sku_details',
    minWidth: '350px',
    label: 'Details',
  },
  {
    id: 'display',
    numeric: false,
    label: 'RFQ Display',
    minWidth: '180px',
  },
  {
    id: 'available_in',
    numeric: false,
    label: 'HAPO Display',
    minWidth: '50px',
  },
  {
    id: 'tag',
    numeric: true,
    label: 'Feature',
  },
  {
    id: 'min_max',
    numeric: true,
    label: 'Min / Max',
    minWidth: '100px',
  },
];

enum FILTER_OPTION {
  ALL = 'All',
  ERROR = 'Error',
  WARNING = 'Warning',
}

enum WARNING_TYPE {
  ADD,
  EDIT,
  NONE,
}

const CustomerPortalPageRfqManagement = (props: any) => {
  const docsPerPage = 10;
  const [ skus, setSkus ] = useState<SkuTypeTransformedAdmin[]>([]);
  const [ dataLoaded, setDataLoaded ] = useState(false);
  const [ warningType, setWarningType ] = useState<{
    type: WARNING_TYPE;
    message: string;
  }>({
    type: WARNING_TYPE.NONE,
    message: '',
  });
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ filterState, setFilterState ] = useState(FILTER_OPTION.ALL);
  const [ searchPhrase, setSearchPhrase ] = useState<string | undefined>('');
  const [ addingSku, setAddingSku ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);
  const [ editingSku, setEditingSku ] = useState<SkuTypeTransformedAdmin | null>(
    null
  );
  const [ skuToSubmit, setSkuToSubmit ] = useState<SkuTypeTransformedAdmin | null>(
    null
  );

  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const start = (currentPage - 1) * docsPerPage;
  const end = currentPage * docsPerPage;

  const isEditProductCatalogAllowed = UserPermissionsHelper.isEditProductCatalogAllowed();

  /* Lifecycle */

  useTrackPageViewEvent(PRODUCT_CATALOG_MANAGEMENT);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Product Catalog Management');
  }, []);

  useEffect(() => {
    if (!dataLoaded) {
      try {
        fetchLicenseData();
        setDataLoaded(true);
      } catch (e) {
        console.log(e);
      }
    }
  });

  /* Event Handlers */
  const handlePaginationClick = (page: number) => {
    setCurrentPage(page);
  };

  const fetchLicenseData = async () => {
    const { data } = await axios.get(`${RFQ_PRODUCTS_URL}?selection=all`, {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        [Header.SELECTED_ACCOUNT_ID]: state.companyId,
        [Header.AUTH_TYPE]: getAuthType(),
      },
    });

    // Unwind sku display data
    const temp = (data.data || []).map((sku: any) => {
      const show_basic = canShowLicense('Basic', sku.display);
      const show_standard = canShowLicense('Standard', sku.display);
      const show_new_orchestrator = canShowLicense('CRPA Orchestrator', sku.display);
      const show_cloud = canShowLicense('Cloud', sku.display);
      const show_standalone = canShowLicense('No Orchestrator', sku.display);
      const show_nonProd = canShowLicense('NonProduction', sku.display);
      const show_automationSuite = canShowLicense('Automation Suite', sku.display);
      return {
        ...sku,
        show_basic,
        show_standard,
        show_new_orchestrator,
        show_cloud,
        show_standalone,
        show_nonProd,
        show_automationSuite,
      };
    });

    setSkus(temp);
  };

  const getNewDisplayHapoOptions = (sku: SkuTypeTransformedAdmin) => ({
    ...sku.display_hapo,
    Basic: booleanValueToDisplayStringValue(sku.showHapo.Basic),
    Standard: booleanValueToDisplayStringValue(sku.showHapo.Standard),
    'CRPA Orchestrator': booleanValueToDisplayStringValue(
      sku.showHapo['CRPA Orchestrator']
    ),
    NonProduction: booleanValueToDisplayStringValue(
      sku.showHapo.NonProduction
    ),
    'No Orchestrator': booleanValueToDisplayStringValue(
      sku.showHapo['No Orchestrator']
    ),
    Cloud: booleanValueToDisplayStringValue(sku.showHapo.Cloud),
    ProcessMining: booleanValueToDisplayStringValue(
      sku.showHapo.ProcessMining
    ),
    'Automation Suite': booleanValueToDisplayStringValue(
      sku.showHapo['Automation Suite']
    ),
  });

  const handleSearchOnChange = (val: string) => {
    setSearchPhrase(val);
  };

  const handleAddLicenseClick = (status: boolean) => {
    setAddingSku(status);
  };

  const handleEditRowClick = (sku: any) => {
    setEditingSku(sku);
  };

  const showWarning = async (sku: SkuTypeTransformedAdmin, type: WARNING_TYPE) => {
    setSkuToSubmit({ ...sku });
    try {
      const res = await axiosGet(
        `${RFQ_MAPPING_CHECK_URL}`,
        state.companyId,
        await getAccessToken(),
        {
          params: {
            sfdc_code: sku.product_code,
            hapo_display_options: JSON.stringify(getNewDisplayHapoOptions(sku)),
          },
        }
      );
      if (res.data.data.error) {
        const message =
          res.data.data.mappedDeploymentTypes.length > 0
            ? `There is a mismatch in mapping. Currently mapped deployment types are: ${res.data.data.mappedDeploymentTypes}.`
            : `Could not find any mapped deployment type.`;
        setWarningType({
          type,
          message,
        });
        return true;
      }
    } catch (e) {
      // fail silently
      setWarningType({
        type: WARNING_TYPE.NONE,
        message: '',
      });
    }
    return false;
  };

  // For add new license modal
  const handleAddLicenseModalSubmit = async (sku: SkuTypeTransformedAdmin) => {
    try {
      await axios.post(
        `${RFQ_PRODUCTS_URL}`,
        {
          values: {
            _id: sku._id,
            sku_name: sku.sku_name,
            product_code: sku.product_code,
            long_description: sku.long_description,
            min_quantity: sku.min_quantity,
            max_quantity: sku.max_quantity,
            short_description: sku.short_description,
            tag: sku.tag,
            display: {
              ...sku.display,
              Basic: booleanValueToDisplayStringValue(sku.showRfq.Basic),
              Standard: booleanValueToDisplayStringValue(sku.showRfq.Standard),
              'CRPA Orchestrator': booleanValueToDisplayStringValue(sku.showRfq['CRPA Orchestrator']),
              NonProduction: booleanValueToDisplayStringValue(sku.showRfq.NonProduction),
              'No Orchestrator': booleanValueToDisplayStringValue(
                sku.showRfq['No Orchestrator']
              ),
              Cloud: booleanValueToDisplayStringValue(sku.showRfq.Cloud),
              'Automation Suite': booleanValueToDisplayStringValue(sku.showRfq['Automation Suite']),
            },
            display_hapo: getNewDisplayHapoOptions(sku),
            show_in: sku.show_in,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: getAuthType(),
          },
        }
      );

      setDataLoaded(false);
      handleAddLicenseModalClose();
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `${sku.sku_name} has been successfully added.`,
      });
    } catch (e) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `An error occurred while adding ${sku.sku_name}. ${e.message}`,
      });
    }
  };

  const booleanValueToDisplayStringValue = (bool: boolean) => {
    if (bool) {
      return 'Show';
    }
    return 'Hide & Remove';
  };

  // For edit modal
  const handleEditModalSubmit = async (sku: SkuTypeTransformedAdmin) => {
    try {
      await axios.put(
        `${RFQ_PRODUCTS_URL}/${sku._id}`,
        {
          values: {
            sku_name: sku.sku_name,
            product_code: sku.product_code,
            long_description: sku.long_description,
            min_quantity: sku.min_quantity,
            max_quantity: sku.max_quantity,
            short_description: sku.short_description,
            tag: sku.tag,
            display: {
              ...sku.display,
              Basic: booleanValueToDisplayStringValue(sku.showRfq.Basic),
              Standard: booleanValueToDisplayStringValue(sku.showRfq.Standard),
              'CRPA Orchestrator': booleanValueToDisplayStringValue(sku.showRfq['CRPA Orchestrator']),
              NonProduction: booleanValueToDisplayStringValue(sku.showRfq.NonProduction),
              'No Orchestrator': booleanValueToDisplayStringValue(
                sku.showRfq['No Orchestrator']
              ),
              Cloud: booleanValueToDisplayStringValue(sku.showRfq.Cloud),
              'Automation Suite': booleanValueToDisplayStringValue(sku.showRfq['Automation Suite']),
            },
            active: sku.active,
            show_in: sku.show_in,
            display_hapo: {
              ...sku.display_hapo,
              Basic: booleanValueToDisplayStringValue(sku.showHapo.Basic),
              Standard: booleanValueToDisplayStringValue(sku.showHapo.Standard),
              'CRPA Orchestrator': booleanValueToDisplayStringValue(sku.showHapo['CRPA Orchestrator']),
              NonProduction: booleanValueToDisplayStringValue(
                sku.showHapo.NonProduction
              ),
              'No Orchestrator': booleanValueToDisplayStringValue(
                sku.showHapo['No Orchestrator']
              ),
              Cloud: booleanValueToDisplayStringValue(sku.showHapo.Cloud),
              ProcessMining: booleanValueToDisplayStringValue(
                sku.showHapo.ProcessMining
              ),
              'Automation Suite': booleanValueToDisplayStringValue(sku.showHapo['Automation Suite']),
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: getAuthType(),
          },
        }
      );
      setDataLoaded(false);
      handleEditModalClose();
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `${sku.sku_name} has been successfully edited.`,
      });
    } catch (e) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `An error occurred while updating ${sku.sku_name}. ${e.message}`,
      });
    }
  };

  const handleInactivateSku = async (sku: any) => {
    const tempSku = cloneDeep(sku);
    [
      'show_basic',
      'show_standard',
      'show_new_orchestrator',
      'show_cloud',
      'show_standalone',
      'show_nonProd',
      'show_automationSuite',
    ].forEach(prop => delete tempSku[prop]);
    tempSku.active = !tempSku.active;

    try {
      await axios.put(
        `${RFQ_PRODUCTS_URL}/${sku._id}`,
        { values: tempSku },
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            [Header.SELECTED_ACCOUNT_ID]: state.companyId,
            [Header.AUTH_TYPE]: getAuthType(),
          },
        }
      );
      setDataLoaded(false);
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `${sku.sku_name} has been successfully edited.`,
      });
    } catch (e) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `An error occurred while updating ${sku.sku_name}. ${e.message}`,
      });
    }
  };

  const handleAddLicenseModalClose = () => {
    setSubmitting(false);
    setAddingSku(false);
  };

  const handleEditModalClose = () => {
    setSubmitting(false);
    setEditingSku(null);
  };

  const handleFilterOptionClick = (selectedOption: any) => {
    setFilterState(selectedOption.value);
  };

  const handleConfirmationModalSubmit = async () => {
    if (skuToSubmit) {
      setSubmitting(true);
      switch (warningType.type) {
        case WARNING_TYPE.ADD:
          await handleAddLicenseModalSubmit(skuToSubmit);
          break;
        case WARNING_TYPE.EDIT:
          await handleEditModalSubmit(skuToSubmit);
          break;
      }
    }
    setWarningType({
      type: WARNING_TYPE.NONE,
      message: '',
    });
  };

  const handleConfirmationModalClose = () => {
    setSubmitting(false);
    setWarningType({
      type: WARNING_TYPE.NONE,
      message: '',
    });
  };

  const handleAddFormSubmit = async (sku: SkuTypeTransformedAdmin) => {
    if (await showWarning(sku, WARNING_TYPE.ADD)) {
      return;
    }
    setSubmitting(true);
    await handleAddLicenseModalSubmit(sku);
  };

  const handleEditFormSubmit = async (sku: SkuTypeTransformedAdmin) => {
    if (await showWarning(sku, WARNING_TYPE.EDIT)) {
      return;
    }
    setSubmitting(true);
    await handleEditModalSubmit(sku);
  };

  const filteredSkus = skus.filter(sku => {
    const phrase = searchPhrase?.toString() ?? '';
    const checkForError = filterState === FILTER_OPTION.ERROR;
    const checkForWarnings = filterState === FILTER_OPTION.WARNING;
    const phaseCheck = phrase
      ? new RegExp(phrase, 'gi').test(sku.sku_name) ||
        new RegExp(phrase, 'gi').test(sku._id) ||
        new RegExp(phrase, 'gi').test(sku.product_code)
      : true;

    const warningCheck = checkForWarnings
      ? sku.warning !== undefined && sku.active
      : true;
    const errorCheck = checkForError
      ? sku.error !== undefined && sku.active
      : true;
    return phaseCheck && errorCheck && warningCheck;
  });

  return (
    <div data-testid="CustomerPortalPageRFQManagement">
      <Helmet>
        <title>Customer Portal | Product Catalog Management</title>
      </Helmet>

      <analyticyStyled.AnalyticsWrapper>
        <BasicHero
          title="Manage Product Catalog"
          subtitle="Data is loaded daily from Salesforce. Values from Salesforce will not be editable."
        />

        <Container>
          {dataLoaded && (
            <div>
              <ProductCatalogWrapper className="CustomerPortalProductCatalog__Container">
                <h3 className="marginBottomh3"> Commercial SKUs </h3>
                <analyticyStyled.FilterContainer className="CustomerPortalProductCatalog__searchInputWrapper">
                  <CustomerPortalSearchInput
                    handleOnChange={handleSearchOnChange}
                    handleSubmit={() => {}}
                    placeholderText="Search by Name, Id, or Product Code"
                    keyword={searchPhrase || ''}
                  />
                  <div className="CustomerPortalProductCatalog__searchActions">
                    <SelectWithSearch
                      options={[
                        {
                          label: FILTER_OPTION.ALL,
                          value: FILTER_OPTION.ALL,
                        },
                        {
                          label: FILTER_OPTION.ERROR,
                          value: FILTER_OPTION.ERROR,
                        },
                        {
                          label: FILTER_OPTION.WARNING,
                          value: FILTER_OPTION.WARNING,
                        },
                      ]}
                      searchable={false}
                      value={filterState}
                      className="Custom-Select--White CustomerPortalProductCatalog__filter"
                      onChange={handleFilterOptionClick}
                      placeholder={
                        <div data-testid="filter-by">
                          {'Filter by: '}
                          <span className="Custom-Select__Placeholder-Value">
                            {filterState}
                          </span>
                        </div>
                      }
                    />
                    {isEditProductCatalogAllowed && (
                      <div style={{
                        margin: '20px 0',
                        textAlign: 'right',
                      }}>
                        <Button
                          className="CustomerPortalProductCatalog__addNewSkuBtn"
                          onClick={(e: any) => handleAddLicenseClick(true)}
                          text="Add new SKU"
                        />
                      </div>
                    )}
                  </div>
                </analyticyStyled.FilterContainer>

                <CPTableContainer className="Table__Normal">
                  <Table
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        {headCells.map(headCell => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'center' : 'left'}
                            style={{ minWidth: headCell.minWidth || 0 }}
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                        {isEditProductCatalogAllowed && (
                          <TableCell
                            style={{ minWidth: '100px' }}
                            align="center"
                            sortDirection={false}
                          >
                            Actions
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredSkus
                        .slice(start, end)
                        .map((sku: SkuTypeTransformedAdmin) => (
                          <TableRow
                            key={sku._id}
                            data-testid="Product__Row">
                            <TableCell align="left">
                              <div className="CustomerPortalProductCatalog__header">
                                {sku.error && (
                                  <Tooltip
                                    placement="bottom"
                                    title={sku.error}
                                  >
                                    <img
                                      className="CustomerPortalProductCatalog__errorIcon"
                                      src={ErrorIcon}
                                      alt="Error"
                                      data-testid="error-icon"
                                    />
                                  </Tooltip>
                                )}
                                {sku.warning && (
                                  <Tooltip
                                    placement="bottom"
                                    title={sku.warning}
                                  >
                                    <img
                                      className="CustomerPortalProductCatalog__errorIcon"
                                      src={WarningIcon}
                                      alt="Error"
                                      data-testid="warning-icon"
                                    />
                                  </Tooltip>
                                )}
                                <h3 className="CustomerPortalProductCatalog__skuTitle">
                                  {sku.sku_name}
                                </h3>
                              </div>
                              <p className="CustomerPortalProductCatalog__skuId">
                                <b>Id: </b>
                                {sku._id}
                                {' '}
/
                                <b>Product Code:</b>
                                {' '}
                                {sku.product_code}
                              </p>
                              <p className="CustomerPortalProductCatalog__skuLongDescriptionTitle">
                                Long Description
                              </p>
                              <div className="CustomerPortalProductCatalog__skuLongDescription">
                                {sku.long_description}
                              </div>
                              <br />
                              <p className="CustomerPortalProductCatalog__skuShortDescriptionTitle">
                                Short Description
                              </p>
                              <div
                                className="CustomerPortalProductCatalog__skuShortDescription"
                                dangerouslySetInnerHTML={{ __html: sku.short_description ?? '' }}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <>
                                {Object.entries(sku.display || {})
                                  .filter(([ key, value ]) =>
                                    canShowLicenseStr(value)
                                  )
                                  .map(([ key ]) => (
                                    <Chip
                                      key={sku._id + '_' + key}
                                      label={key}
                                    />
                                  ))}
                              </>
                            </TableCell>
                            <TableCell align="left">
                              {Object.entries(sku.display_hapo || {})
                                .filter(([ key, value ]) =>
                                  canShowLicenseStr(value)
                                )
                                .map(([ displayProperty ]) => (
                                  <Chip
                                    key={sku._id + '_' + displayProperty}
                                    label={displayProperty}
                                  />
                                ))}
                            </TableCell>
                            <TableCell align="center">{sku.tag}</TableCell>
                            <TableCell align="center">
                              {sku.min_quantity ? sku.min_quantity : '0'}
                              {' '}
/
                              {' '}
                              {sku.max_quantity ? sku.max_quantity : 0}
                            </TableCell>
                            {isEditProductCatalogAllowed && (
                              <TableCell>
                                <Button
                                  className="CustomerPortalProductCatalog__editSkuBtn"
                                  onClick={(e: any) => handleEditRowClick(sku)}
                                  text="Edit"
                                />
                                <Button
                                  className={`CustomerPortalProductCatalog__editSkuStatusBtn ${
                                    sku.active
                                      ? 'CustomerPortalProductCatalog__editSkuStatusBtn--inactivate'
                                      : 'CustomerPortalProductCatalog__editSkuStatusBtn--reactivate'
                                  }`}
                                  onClick={(e: any) => handleInactivateSku(sku)}
                                  text={
                                    sku.active ? 'Inactivate' : 'Reactivate'
                                  }
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <CustomerPortalPagination
                    className="CustomerPortalProductCatalog__pagination"
                    activePage={currentPage}
                    handleClick={handlePaginationClick}
                    numPagesBeforeEllipses={5}
                    numResultsPerPage={docsPerPage}
                    numResults={filteredSkus.length}
                  />
                </CPTableContainer>
              </ProductCatalogWrapper>
            </div>
          )}

          {!dataLoaded && (
            <analyticyStyled.analyticsLoader data-testid="CustomerPortalLoader">
              <div>
                <CustomerPortalLoader />
              </div>
            </analyticyStyled.analyticsLoader>
          )}
        </Container>
      </analyticyStyled.AnalyticsWrapper>

      {addingSku && (
        <AdminAddModal
          submitting={submitting}
          handleFormSubmit={handleAddFormSubmit}
          handleClose={handleAddLicenseModalClose}
        />
      )}

      {editingSku && (
        <AdminEditModal
          sku={editingSku}
          submitting={submitting}
          handleFormSubmit={handleEditFormSubmit}
          handleClose={handleEditModalClose}
        />
      )}

      <ConfirmationModal
        modalHeading="Warning"
        primaryButtonText="Confirm"
        secondaryButtontext="Cancel"
        message={warningType.message}
        open={warningType.type !== WARNING_TYPE.NONE}
        handleConfirm={handleConfirmationModalSubmit}
        handleClose={handleConfirmationModalClose}
      />
    </div>
  ); // End of return
};

export default CustomerPortalPageRfqManagement;
