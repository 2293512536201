import type { EntityDTO } from '@customer-portal/interfaces';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Redirect } from 'react-router-dom';

import {
  axiosGet,
  axiosPost,
} from '../../client/axios';
import NoCompanyId from '../../components/CustomerPortal-NoCompanyId';
import {
  isBasicCaseType,
  isPremiumCaseType,
} from '../../constants/account.constants';
// Constants
import {
  ENTITIES_URL,
  SFDC_PREMIUM_DASHBOARD_URL,
  SFDC_URL,
  STATUS_UPDATES_CURRENT_INCIDENTS_URL,
} from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
// Interfaces
import type { ISupportList } from '../../interfaces/supportTickets.interface';
// Utils
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
import { featureFlag } from '../../utils/featureFlag';
import CustomerPortalSupportDashboard from './CustomerPortalSupportDashboard';
// Components
import CustomerPortalSupportIncident from './SupportIncident/CustomerPortalSupportIncident';
import CustomerPortalSupportPremium from './SupportPremium/CustomerPortalSupportPremium';

const CustomerPortalSupportFactory = (props: any) => {
  const sectionParam = new URLSearchParams(props.location.search).get(
    'section'
  );
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ premiumList, setPremiumList ] = useState<ISupportList[]>([]);
  const [ incidentList, setIncidentList ] = useState<ISupportList[]>([]);
  const [ dashboardData, setDashboardData ] = useState({});
  const [ isEntityFiltersEnabled, setIsEntityFiltersEnabled ] = useState(false);
  const [ statusUpdatesIncidents, setStatusUpdatesIncidents ] = useState([]);

  const canViewPremium = UserPermissionsHelper.isViewPremiumSupportAllowed();
  const canViewIncident = UserPermissionsHelper.isViewSupportAllowed();
  const canUserSupportRestrictedAccount = UserPermissionsHelper.canUserSupportRestrictedAccount();

  const fetchCasesData = async (): Promise<ISupportList[]> => {
    const res = await axiosGet<{
      status: string;
      statusCode: number;
      results: ISupportList[];
    }>(
      `${SFDC_URL}/cases`,
      state.companyId,
      await getAccessToken(),
    );

    return res?.data?.results ?? [];
  };

  const fetchEntityData = async (cases: ISupportList[]): Promise<Record<string, EntityDTO>> => {
    const licenseCodes = cases.map((el) => el.License_Code__c ?? '').filter((code) => !!code);
    const res = await axiosPost<Record<string, EntityDTO>>(
      `${ENTITIES_URL}/licenses`,
      state.companyId,
      await getAccessToken(),
      { licenseCodes: new Set(licenseCodes) }
    );

    return res?.data ?? {};
  };

  const fetchSFDCData = async () => {
    try {
      const cases = await fetchCasesData();
      const licenseCodeToEntityMap = await fetchEntityData(cases);
      const decoratedCases = cases.map((caseObj) => ({
        ...caseObj,
        Entity: caseObj.License_Code__c && licenseCodeToEntityMap[caseObj.License_Code__c]
          ? licenseCodeToEntityMap[caseObj.License_Code__c]
          : null,
      }));

      setIncidentList(decoratedCases.filter((el) => isBasicCaseType(el.RecordTypeId)));
      setPremiumList(decoratedCases.filter((el) => isPremiumCaseType(el.RecordTypeId)));

      const isEntityManagementEnabled = await featureFlag.isEntityManagementEnabled(
        state.companyId,
        await getAccessToken()
      );
      setIsEntityFiltersEnabled(isEntityManagementEnabled);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchDashboardData = async () => {
    try {
      const dashboardResults = await axiosGet(
        `${SFDC_PREMIUM_DASHBOARD_URL}/cases/proactiveCare/dashboard`,
        state.companyId,
        await getAccessToken()
      );

      if (dashboardResults?.data?.dashboardData) {
        setDashboardData(dashboardResults.data.dashboardData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCurrentIncidentsData = async () => {
    try {
      const currentIncidents = await axiosGet(
        STATUS_UPDATES_CURRENT_INCIDENTS_URL,
        state.companyId,
        await getAccessToken()
      );
      setStatusUpdatesIncidents(currentIncidents.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (state.companyId) {
      Promise.all([
        fetchSFDCData(),
        fetchDashboardData(),
        fetchCurrentIncidentsData(),
      ])
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return () => {
      setIncidentList([]);
      setPremiumList([]);
      setDashboardData({});
    };
  }, [ props.location.pathname, state.companyId ]);

  // Early return if no company id
  if (!state.companyId) {
    return <NoCompanyId />;
  }

  if (!canUserSupportRestrictedAccount) {
    return <Redirect to="/support-restricted" />;
  }

  switch (sectionParam) {
    case 'cases-premium':
      if (canViewPremium) {
        return (
          <CustomerPortalSupportPremium
            incidentsList={premiumList}
            isLoading={isLoading}
            isEntityFiltersEnabled={isEntityFiltersEnabled}
          />
        );
      }
      // Go to Incident
      return <Redirect to="/unauthorized" />;

    case 'tickets-dashboard':
      if (canViewPremium) {
        return <CustomerPortalSupportDashboard data={dashboardData} />;
      }
      return <Redirect to="/unauthorized" />;

    case 'tickets-incident':
      if (canViewIncident) {
        return (
          <CustomerPortalSupportIncident
            incidentsList={incidentList}
            isLoading={isLoading}
            isEntityFiltersEnabled={isEntityFiltersEnabled}
          />
        );
      }
      return <Redirect to="/unauthorized" />;

    default:
      if (canViewIncident) {
        return (
          <CustomerPortalSupportIncident
            incidentsList={incidentList}
            isLoading={isLoading}
            isEntityFiltersEnabled={isEntityFiltersEnabled}
          />
        );
      } else if (canViewPremium) {
        return (
          <CustomerPortalSupportPremium
            incidentsList={premiumList}
            isLoading={isLoading}
            isEntityFiltersEnabled={isEntityFiltersEnabled}
          />
        );
      }
      return <Redirect to="/unauthorized" />;
  }
};

export default CustomerPortalSupportFactory;
