import {
  FormControl,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import React, {
  useContext,
  useState,
} from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  ProductContainer,
  ProductQty,
} from '../../../assets/css/Admin/Admin-RFQModal';
import * as styles from '../../../assets/css/HAPO/RequestHistory/AddHistoryOrder';
import {
  BundleCard,
  BundleCardContainer,
  BundleParent,
  BundleParentContainer,
} from '../../../assets/css/HAPO/RequestHistory/EditPastRequest';
import { axiosPatch } from '../../../client/axios';
import Container from '../../../components/CustomerPortal-New-Container';
import ScrollToTop from '../../../components/CustomerPortal-ScrollToTop';
import SubmitButtonsContainer from '../../../components/HAPO/AddHistory/SubmitButtonsContainer';
import TextInput from '../../../components/Text-Input';
import TextLabel from '../../../components/TextLabel';
import { HAPO_ORDER_URL } from '../../../constants/network.constants';
import { STANDALONE } from '../../../constants/requestForQuote.constants';
import { useAuth } from '../../../contexts/auth';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import { StoreContext } from '../../../store';

const EditPastRequest = (props: any) => {
  const { t } = useTranslation('common');
  const isCompleteHAPOOrderAllowed = UserPermissionsHelper.isCompleteHAPOOrderAllowed();
  const history = useHistory();
  const location = useLocation<any>();
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const theme = useTheme();
  const orderId = location.state.orderInfo._id;
  const note = location.state.orderInfo.note;

  const [ canSubmit, setCanSubmit ] = useState<boolean>(false);
  // Bundles, note, phoneNumber, version, changeReason
  const [ orderProperties, setOrderProperties ] = useState({
    bundles: location.state.orderInfo.bundles,
    changeReason: '',
  });

  if (!isCompleteHAPOOrderAllowed) {
    return <Redirect to="/unauthorized" />;
  }

  // Event Handlers

  const handleInputBlur = (propertyName: string, value: string) => {
    setOrderProperties({
      ...orderProperties,
      [propertyName]: value,
    });
  };

  const handleBundleCodeOnBlur = (
    parentIndex: number,
    parentLicenseCode: string
  ) => {
    if (!parentLicenseCode) {
      return;
    }

    const newBundles = cloneDeep(orderProperties.bundles);
    newBundles[parentIndex].license_code = parentLicenseCode;

    setOrderProperties({
      ...orderProperties,
      bundles: newBundles,
    });
  };

  const handleBundleInputOnBlur = (
    parentIndex: number,
    parentQuantity?: string,
    childIndex?: number,
    childQuantity?: string
  ) => {
    const newBundles = cloneDeep(orderProperties.bundles);
    const {
      quantity, products,
    } = orderProperties.bundles[parentIndex];

    const parentQuantityInt = parseInt(parentQuantity || '0', 10);

    newBundles[parentIndex].quantity = parentQuantity
      ? parentQuantityInt
      : quantity;

    if (childIndex !== undefined && childQuantity) {
      const childQuantityInt = parseInt(childQuantity || '0', 10);
      const newProducts = cloneDeep(products);
      newProducts[childIndex].quantity = childQuantityInt;

      newBundles[parentIndex].products = newProducts;
    }

    setOrderProperties({
      ...orderProperties,
      bundles: newBundles,
    });
  };

  const handleQuantityFormSubmit = async () => {
    // Scan bundles for any parents of quantity = 0 to remove
    const bundles = orderProperties.bundles.filter(
      (bundle: any) => bundle.quantity !== 0
    );

    // Also remove the child products if quantity = 0
    bundles.forEach((bundle: any) => {
      if (Array.isArray(bundle.products)) {
        bundle.products = bundle.products.filter(
          (product: any) => product.quantity !== 0
        );
      }
    });
    try {
      await axiosPatch(
        `${HAPO_ORDER_URL}/${orderId}`,
        state.companyId,
        await getAccessToken(),
        {
          bundles,
          change_reason: orderProperties.changeReason,
        }
      );

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t('request_saved_successful', 'Request saved'),
      });
      history.replace('/hapo/request-history');
    } catch (e) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      const errMessage = e.response?.data?.message ?? e.message;
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'hapo_checkout_cart_error_msg',
          `There was an error in submitting your request. Please try again. ${errMessage}`,
          { errMessage }
        ),
      });
    }
  };

  const handleGoBack = () => {
    history.go(-1);
  };

  const generateBundlesListing = () => (
    <BundleCardContainer data-testid="HapoEditPastRequest__BundlesContainer">
      {orderProperties.bundles.map((bundle: any, index: number) => {
        const {
          sku_name,
          existing_license_name,
          license_code,
          type,
          quantity,
          products,
        } = bundle;

        const skuName = existing_license_name ?? sku_name ?? '';

        return (
          <BundleCard key={index}>
            <BundleParentContainer>
              <BundleParent>
                <span>
                  <strong>{skuName}</strong>
                </span>
                <br />
                {type && (
                  <span>
                    <strong>{type}</strong>
                  </span>
                )}
                <br />
                {license_code && (
                  <FormControl>
                    <TextLabel
                      label={t(
                        'past_requests_hapo_product_tooltip_license_code',
                        'License code'
                      )}
                      required
                    />
                    <TextField
                      id={`hapo-edit-request-input-bundlename-${index}`}
                      defaultValue={license_code}
                      onBlur={e => {
                        handleBundleCodeOnBlur(index, e.target.value);
                      }}
                      inputProps={{ 'data-testid': 'HapoRequestHistory__LicenseCodeInput' }}
                    />
                  </FormControl>
                )}
              </BundleParent>
              {quantity && (
                <ProductQty>
                  <FormControl>
                    <TextLabel
                      label={t(
                        'past_requests_hapo_column_quantity',
                        'Quantity'
                      )}
                    />
                    <TextField
                      disabled={type !== STANDALONE}
                      id={`hapo-edit-request-input-bundlequantity-${index}`}
                      type="number"
                      defaultValue={quantity}
                      className="AddHistoryOrder__QuantityInput"
                      onBlur={e => {
                        handleBundleInputOnBlur(index, e.target.value);
                      }}
                      inputProps={{ 'data-testid': 'HapoRequestHistory__QuantityInput' }}
                    />
                  </FormControl>
                </ProductQty>
              )}
            </BundleParentContainer>
            {Array.isArray(products) && products.length > 0 && (
              <ProductContainer>
                {products.map((childProduct: any, childIndex: number) => (
                  <BundleParentContainer key={childIndex}>
                    <BundleParent>{childProduct.sku_name}</BundleParent>
                    <ProductQty>
                      <FormControl>
                        <TextLabel
                          label={t(
                            'past_requests_hapo_column_quantity',
                            'Quantity'
                          )}
                        />
                        <TextField
                          id={`hapo-edit-request-input-childquantity-${childIndex}`}
                          className="AddHistoryOrder__QuantityInput"
                          type="number"
                          defaultValue={childProduct.quantity}
                          onBlur={e => {
                            handleBundleInputOnBlur(
                              index,
                              undefined,
                              childIndex,
                              e.target.value
                            );
                          }}
                          inputProps={{ 'data-testid': 'HapoRequestHistory__QuantityInput' }}
                        />
                      </FormControl>
                    </ProductQty>
                  </BundleParentContainer>
                ))}
              </ProductContainer>
            )}
          </BundleCard>
        );
      })}
    </BundleCardContainer>
  );

  return (
    <div data-testid="HapoEditPastRequest">
      <>
        <ScrollToTop />
        <Helmet>
          <title>{t('edit_order', 'Edit Order')}</title>
        </Helmet>

        <styles.PageWrapper data-testid="HapoEditPastRequest__form">
          <styles.HomeSection>
            <Container cssClass="CustomerPortalPage__container">
              <div
                className="heroBackButton"
                onClick={() => {
                  handleGoBack();
                }}
                data-testid="HapoRequestHistory__goBackBtn"
              >
                {t('past_requests_hapo_go_back_btn', 'Go Back')}
              </div>

              <styles.heroTitleWrapper>
                <styles.heroTitle>
                  {t('edit_order', 'Edit Order')}
                </styles.heroTitle>
              </styles.heroTitleWrapper>

              <styles.FormTableContainer className="AddHistoryOrder__FormContainer">
                <div className="AddHistoryOrder__FormDataContainer">
                  <FormControl>
                    <TextLabel
                      label="ID"
                    />
                    <TextInput
                      id="hapo-edit-request_input-orderId"
                      value={orderId}
                      disabled
                    />
                  </FormControl>
                  <FormControl>
                    <TextLabel
                      label={t('header_menu_company_licenses', 'Licenses')}
                    />
                    {generateBundlesListing()}
                  </FormControl>
                </div>
                <FormControl style={{ width: '100%' }}>
                  <TextLabel
                    label={t('hapo_checkout_notes_text_field_label', 'Notes')}
                  />
                  <TextareaAutosize
                    disabled
                    placeholder={t(
                      'support_form_description_placeholder',
                      'Please add a detailed description here...'
                    )}
                    value={note}
                    aria-label={t(
                      'hapo_checkout_notes_text_field_label',
                      'Notes'
                    )}
                    minRows={5}
                    data-testid="HapoEditPastRequest__DescriptionInput"
                    style={{
                      backgroundColor: theme.palette.semantic.colorBackground,
                      color: theme.palette.semantic.colorForeground,
                    }}
                  />
                </FormControl>
                <FormControl style={{ width: '100%' }}>
                  <TextLabel
                    label={t('change_reason', 'Change Reason')}
                    required
                  />
                  <TextareaAutosize
                    placeholder={t(
                      'form_reason_placeholder',
                      'Please add a reason for change...'
                    )}
                    defaultValue={orderProperties.changeReason}
                    onBlur={e =>
                      handleInputBlur('changeReason', e.target.value)}
                    onChange={(event: any) => {
                      setCanSubmit(event.target.value?.length > 0);
                    }}
                    aria-label={t('change_reason', 'Change Reason')}
                    minRows={5}
                    data-testid="HapoRequestHistory__ReasonInput"
                  />
                </FormControl>
                <SubmitButtonsContainer
                  disabled={!canSubmit}
                  handleFormSubmit={handleQuantityFormSubmit}
                  handleCancelButtonClick={handleGoBack}
                />
              </styles.FormTableContainer>
            </Container>
          </styles.HomeSection>
        </styles.PageWrapper>
      </>
    </div>
  );
};

export default EditPastRequest;
