import type { RoleWithMetadata } from '@customer-portal/constants';
import {
  ADMIN_USER_ROLE_TO_DISPLAY_NAME,
  UserRole,
} from '@customer-portal/constants';
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Icon,
  IconButton,
  Paper,
  TextField,
} from '@mui/material';
import {
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import { axiosGet } from '../../../client/axios';
// Constants
import { BROWSE_CUSTOMERS_URL } from '../../../constants/network.constants';
import { useAuth } from '../../../contexts/auth';
import { isProduction } from '../../../lib/env.utils';
import type { AdminUser } from '../../../pages/Admin/user-management';
import { StoreContext } from '../../../store';
import Button from '../../Button/Button';
// Components
import Modal from '../../Modal';
import type { SelectedAccountType } from './Admin-SelectAccountsModal';
import SelectAccountsModal from './Admin-SelectAccountsModal';

const Container = styled.div`
  button.MuiButton {
    font-weight: bold !important;
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    color: ${p => p.theme.palette.semantic.colorBackground};
  }

  p, span {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  .MuiInputBase-input {
    background-color: ${p => p.theme.palette.semantic.colorBackground};
  }
`;

const AccountChip = styled(Chip)`
  margin: 0px 4px 8px 4px !important;
  max-width: 50% !important;
  border-color: ${p => p.theme.palette.semantic.colorBorder} !important;

  span.MuiChip-label {
    font-size: 14px;
  }
`;

const AccessToggleLabel = styled(FormControlLabel)`
  & > span {
    font-weight: 700 !important;
  }
`;

const AddAccountButtonDiv = styled.div`
  display: grid;
  padding-top: 8px;
  position: sticky;
  background: ${p => p.theme.palette.semantic.colorBackground};
  bottom: 0;
  padding-bottom: 8px;

  // set button height
  & > button {
    height: 42px;
    &.MuiButton-outlined {
      border: 1px solid ${p => p.theme.palette.semantic.colorPrimary};
      background: transparent;
      color: ${p => p.theme.palette.semantic.colorPrimary};
    }
`;
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FormField = styled.div`
  margin-bottom: ${p => p.theme.spacing(2)}px;
  .Date-Picker {
    .material-icons-outlined {
      color: ${p => p.theme.palette.semantic.colorForeground};
      font-size: ${p => p.theme.spacing(2)}px;
    }
    input {
      border: none;
    }
    .MuiOutlinedInput-root {
      padding-right: ${p => p.theme.spacing(1)}px;
      border: 1px solid ${p => p.theme.palette.grey[400]};
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0);
      }
    }
  }
  .AdminForm_Input {
    .MuiOutlinedInput-root {
      border: none;
      outline: 1px solid ${p => p.theme.palette.semantic.colorBorder};

      &:hover {
        outline: 1px solid ${p => p.theme.palette.semantic.colorPrimary};
      }

      &.Mui-focused {
        outline: 2px solid ${p => p.theme.palette.semantic.colorPrimary};
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const SelectedAccountsDiv = styled(Paper)`
  display: block;
  width: 100%;
  padding: 8px 8px 0px 8px;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 16px;
  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  &.MuiPaper-root {
    background-color: ${p => p.theme.palette.semantic.colorBackground};
  }
`;

const Col = styled.div`
  flex: 1;
  margin: 0 ${p => p.theme.spacing(4)}px;

  &:nth-child(1) {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
    flex: 0 0 40%;
  }

  ${p => p.theme.breakpoints.down('sm')} {
    margin: ${p => p.theme.spacing(2)}px 0;
  }
`;

const FormControlWrapper = styled.div`
  .MuiInputBase-input {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  .MuiFormControlLabel-label {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  .MuiFormHelperText-root {
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.5px;
    color: ${p => p.theme.palette.error.main};
  }
`;

type AddEditAdminUserModalProps = {
  open: boolean;
  admin?: AdminUser;
  saveEdits: (params: {
    email: string;
    roles: Array<(RoleWithMetadata & { companyIds?: string[] })>;
    expirationDate?: Date;
    selectedAccounts?: string [];
  }) => void;
  saveNew: (params: {
    name: string;
    email: string;
    roles: Array<(RoleWithMetadata & { companyIds?: string[] })>;
    expirationDate?: Date;
    selectedAccounts?: string [];
  }) => void;
  handleClose: () => void;
};

const AddEditAdminUserModal = ({
  open,
  admin,
  saveEdits,
  saveNew,
  handleClose,
}: AddEditAdminUserModalProps) => {
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const [ emailHelperText, setEmailHelperText ] = useState('');
  const [ fullnameHelperText, setFullnameHelperText ] = useState('');
  const [ roleHelperText, setRoleHelperText ] = useState('');
  const [ fullname, setFullName ] = useState<string>(admin?.name ?? '');
  const [ email, setEmail ] = useState<string>(admin?.userEmail ?? '');
  const [ roles, setRoles ] = useState<UserRole[]>((admin?.roles ?? []).map(r => r.name));
  const [ expirationDate, setExpirationDate ] = useState<Date | undefined>(
    admin?.roles.find(r => r.name === UserRole.UI_SPECIFIC_ACCOUNT_USER)?.expirationDate
  );
  const [ selectAccountEnabled, setSelectAccountEnabled ] = useState<boolean>(false);
  const [ toggleExpiryDate, setToggleExpiryDate ] = useState<boolean>(
    !!(admin?.roles.find(r => r.name === UserRole.UI_SPECIFIC_ACCOUNT_USER)?.expirationDate)
  );
  const [ selectedAccounts, setSelectedAccounts ] = useState<SelectedAccountType[]>([]);
  const [ loadingAlreadySelectedAccountsData, setLoadingAlreadySelectedAccountsData ] = useState<boolean>(true);
  const [ showSelectAccountsModal, setShowSelectAccountsModal ] = useState<boolean>(false);

  const alreadySelectedAccountIds: string [] | undefined = admin?.roles.find(
    (r) => r.name === UserRole.UI_SPECIFIC_ACCOUNT_USER
  )?.companyIds;
  const fetchAlreadySelectedAccountsData = async () => {
    if (!alreadySelectedAccountIds || alreadySelectedAccountIds.length === 0) {
      setLoadingAlreadySelectedAccountsData(false);
      return;
    }
    // Fetch names for already-selected company IDs
    try {
      const accountsRes: any = await axiosGet(BROWSE_CUSTOMERS_URL,
        state.companyId,
        await getAccessToken(),
        {
          params: {
            action: 'sort',
            sort_by: 'Name',
            sort_direction: 1,
            limit: alreadySelectedAccountIds.length,
            ids: alreadySelectedAccountIds,
          },
        });

      if (accountsRes.status === 200) {
        const alreadySelectedAccounts = accountsRes.data.data.customers.map(
          (account: any) => ({
            companyId: account.id,
            companyName: account.name,
          })
        );
        setSelectedAccounts(alreadySelectedAccounts);
        setLoadingAlreadySelectedAccountsData(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAlreadySelectedAccountsData();
  }, []);

  useEffect(() => {
    setFullName(admin?.name ?? '');
    setEmail(admin?.userEmail ?? '');
    setRoles((admin?.roles ?? []).map(r => r.name));
  }, [ open, admin ]);

  useEffect(() => {
    setSelectAccountEnabled(
      roles.includes(UserRole.UI_SPECIFIC_ACCOUNT_USER)
    );
  }, [ roles ]);

  useEffect(() => {
    const existingExpirationDate = admin?.roles
      .find(r => r.name === UserRole.UI_SPECIFIC_ACCOUNT_USER)
      ?.expirationDate;
    setExpirationDate(toggleExpiryDate ? existingExpirationDate ?? new Date() : undefined);
  }, [ toggleExpiryDate, admin?.roles ]);

  const inEditMode = admin !== undefined;
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleToggleRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val: UserRole = e.target.value as UserRole;
    const set: Set<UserRole> = new Set(roles);
    set.has(val) ? set.delete(val) : set.add(val);
    setRoles(
      Array.from(set)
        .filter(r => Object.keys(ADMIN_USER_ROLE_TO_DISPLAY_NAME).includes(r))
        .sort((a, b) => a.localeCompare(b))
    );
  };

  const validateInputs = () => {
    if (!fullname) {
      setFullnameHelperText('Name is mandatory');
      return 'failed';
    } else if (!email) {
      setEmailHelperText('Email is mandatory');
      return 'failed';
    } else if (isProduction() && !/@uipath.com\s*$/.test(email)) {
      setEmailHelperText('Enter only a uipath.com domain');
      return 'failed';
    } else if (!isProduction() && !/@uipath.com\s*$/.test(email) && !/\.internal@uipath-qa.com\s*$/.test(email)) {
      setEmailHelperText('Enter only a uipath.com/.internal@uipath-qa.com domain for non-prod environments');
      return 'failed';
    } else if (!roles.length) {
      setRoleHelperText('Having at least 1 role is mandatory');
      return 'failed';
    }

    return 'success';
  };

  const closeModal = () => {
    setFullName('');
    setEmail('');
    setRoles([]);
    setEmailHelperText('');
    setFullnameHelperText('');
    setRoleHelperText('');
    handleClose();
  };

  const openSelectAccountsModal = () => {
    setShowSelectAccountsModal(true);
  };

  const handleAccountSelectionClosed = (accounts: SelectedAccountType[]) => {
    accounts.sort((a, b) =>
      a.companyName < b.companyName ? -1 : a.companyName > b.companyName ? 1 : 0
    );
    setSelectedAccounts(accounts);
    setShowSelectAccountsModal(false);
  };

  const handleSave = async () => {
    if (validateInputs() === 'failed') {
      return;
    }
    const finalExpirationDate = selectAccountEnabled && toggleExpiryDate ? expirationDate : undefined;
    const finalSelectedAccounts = selectAccountEnabled ? selectedAccounts.map(a => a.companyId) : undefined;
    inEditMode
      ? saveEdits({
        email,
        roles: roles.map((r) => ({
          name: r,
          ...(r === UserRole.UI_SPECIFIC_ACCOUNT_USER && {
            ...(finalSelectedAccounts?.length && { companyIds: finalSelectedAccounts }),
            ...(finalExpirationDate && { expirationDate: finalExpirationDate }),
          }),
        })),
      })
      : saveNew({
        name: fullname,
        email,
        roles: roles.map((r) => ({
          name: r,
          ...(r === UserRole.UI_SPECIFIC_ACCOUNT_USER && {
            ...(finalSelectedAccounts?.length && { companyIds: finalSelectedAccounts }),
            ...(finalExpirationDate && { expirationDate: finalExpirationDate }),
          }),
        })),
      });
    closeModal();
  };

  return (
    <Modal
      modalTitle="Add/Edit Admin"
      modalDescription="Add/Edit super admin"
      modalHeading={inEditMode ? 'Edit Admin' : 'Add New Admin'}
      innerClasses="Add-User-Modal"
      handleClose={closeModal}
      open={open}
    >
      <Container className="Modal-Fields-Container">
        <Row>
          <Col>
            <Form>
              <FormField>
                <p className="EditProfile__Stat-Title Bold">Full Name</p>
                <FormControlWrapper>
                  <TextField
                    fullWidth
                    className="AdminForm_Input"
                    value={fullname}
                    onChange={e => setFullName(e.target.value)}
                    id="fullname"
                    helperText={fullnameHelperText}
                    variant={'outlined' as any}
                    placeholder="Full Name"
                    disabled={inEditMode}
                  />
                </FormControlWrapper>
              </FormField>
              <FormField>
                <p className="EditProfile__Stat-Title Bold">Email</p>
                <FormControlWrapper>
                  <TextField
                    fullWidth
                    className="AdminForm_Input"
                    value={email}
                    onChange={handleEmailChange}
                    id="email"
                    helperText={emailHelperText}
                    variant={'outlined' as any}
                    color="secondary"
                    placeholder="Email"
                    disabled={inEditMode}
                  />
                </FormControlWrapper>
              </FormField>
              {selectAccountEnabled && (
                <div>
                  <FormField>
                    <AccessToggleLabel
                      control={
                        <Checkbox
                          checked={toggleExpiryDate}
                          onChange={event =>
                            setToggleExpiryDate(event.target.checked)}
                          name="Expiry Date toggle"
                        />
                      }
                      label={`Access Expires (only for ${ADMIN_USER_ROLE_TO_DISPLAY_NAME[UserRole.UI_SPECIFIC_ACCOUNT_USER]})`}
                    />

                    {toggleExpiryDate && (
                      <div>
                        <p className="EditProfile__Stat-Title">
                          Expiration Date
                        </p>
                        <FormField>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              view='day'
                              views={[ 'day' ]}
                              openTo='day'
                              slots={{
                                openPickerButton: (props) => (
                                  <IconButton
                                    {...props}
                                    data-testid='ExpirationDatePicker' />
                                ),
                                openPickerIcon: () => (
                                  <Icon className='material-icons-outlined'>
                                    calendar_today
                                  </Icon>
                                ),
                              }}
                              slotProps={{
                                textField: {
                                  id: 'date-picker-inline',
                                  className: 'Date-Picker',
                                  InputProps: {
                                    'aria-label': 'Change Expiry Date',
                                    className: 'Tall',
                                  },
                                  'aria-label': 'Change Expiry Date',
                                },
                                field: { className: 'Date-Picker' },
                                toolbar: { hidden: true },
                              }}
                              format="MM/dd/yyyy"
                              className="Date-Picker"
                              value={moment(expirationDate).toDate() ?? new Date()}
                              onChange={(date: any) => {
                                setExpirationDate(date);
                              }}
                              disablePast
                              disabled={!toggleExpiryDate}
                            />
                          </LocalizationProvider>
                        </FormField>
                      </div>
                    )}
                  </FormField>
                </div>
              )}
            </Form>
          </Col>
          <Col>
            <p className="EditProfile__Stat-Title Bold">Admin Roles</p>
            <FormControlWrapper>
              <FormControl>
                <FormGroup aria-label="adminType">
                  {Object.entries(ADMIN_USER_ROLE_TO_DISPLAY_NAME).map(([ userRole, displayName ], i) => (
                    <FormControlLabel
                      key={i}
                      value={userRole}
                      control={
                        <Checkbox
                          checked={roles.includes(userRole as UserRole)}
                          onChange={handleToggleRole}
                          name={displayName}
                        />
                      }
                      label={displayName}
                    />
                  ))}
                </FormGroup>
                <FormHelperText>{roleHelperText}</FormHelperText>
              </FormControl>
            </FormControlWrapper>
          </Col>
        </Row>
        {selectAccountEnabled && (
          <div>
            <p className="EditProfile__Stat-Title Bold">
              {`Selected Accounts (only for ${ADMIN_USER_ROLE_TO_DISPLAY_NAME[UserRole.UI_SPECIFIC_ACCOUNT_USER]})`}
            </p>
            <SelectedAccountsDiv
              elevation={3}
              data-testid="SelectedAccountsContainer">
              {(!loadingAlreadySelectedAccountsData && selectedAccounts.length === 0) && (
                <p className="EditProfile__Stat-Title">
                  {`Not specified. Note: The ${ADMIN_USER_ROLE_TO_DISPLAY_NAME[UserRole.UI_SPECIFIC_ACCOUNT_USER]} role is enabled for all accounts if not specified.`}
                </p>
              )}
              {!loadingAlreadySelectedAccountsData && selectedAccounts.map((account, index) => (
                <AccountChip
                  label={account.companyName}
                  variant="outlined"
                  onDelete={(_event: any) => {
                    setSelectedAccounts(prev =>
                      prev.filter((_, i) => i !== index)
                    );
                  }}
                />
              ))}
              <AddAccountButtonDiv>
                <Button
                  className="MuiButton-outlined MuiButton"
                  text='Add Accounts'
                  onClick={openSelectAccountsModal}
                />
              </AddAccountButtonDiv>
            </SelectedAccountsDiv>
          </div>
        )}
        <Button
          className='MuiButton'
          text={inEditMode ? 'Save Changes' : 'Add Admin'}
          onClick={handleSave}
        />
      </Container>
      <SelectAccountsModal
        open={showSelectAccountsModal}
        alreadySelectedAccounts={selectedAccounts}
        handleClose={handleAccountSelectionClosed}
      />
    </Modal>
  );
};

export default AddEditAdminUserModal;
