import { Link } from 'react-router-dom';
import styled from 'styled-components';

import NavIcon from '../../img/svg/arrows/Nav_Icon_ArrowRightWhite_CircleBlue.svg';
import { transitionStandard } from '../../js_mixins/transitions';

interface Props {
  isPublic: boolean;
}

export const SupportCasePageContainer = styled.div<Props>`
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  .NewSupportCasePage__container {
    padding-top: 23px;
    @media (min-width: 1820px) {
      margin-bottom: 0px;
    }
  }
`;

export const SupportCaseValidationErrorMessage = styled.span`
  color: ${p => p.theme.palette.error.main};
  display: block;
  margin-top: 12px;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const SupportCaseUploadedFileInfo = styled.span`
  color: ${p => p.theme.palette.semantic.colorPrimary};
  display: block;
  margin-top: 12px;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const SupportCaseBody = styled.div <{ doneStreamedText?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 34px;
  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  .NewSupportCaseBody {
    margin: 0 30%;
    width: 100%;
    ${p => p.theme.breakpoints.down('sm')} {
      margin: 0;
    }
  }
  .SupportCaseBody__Additional-Details {
    width: 50%;
    height: 100%;
    ${p => p.theme.breakpoints.down('sm')} {
      width: 100%;
      padding: 0;
    }
  }
  .SupportCaseBody__Case-Deflection {
    width: 90%;
    padding: 0 32px;
    min-height: 60vh;
    ${p => p.theme.breakpoints.down('sm')} {
      min-height: 0;
    }
  }
  .SupportCaseBody__Case-Deflection-Response {
    overflow-y: auto;
    max-height: 50vh;
    padding-right: 20px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
      width: 11px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid ${p => p.theme.palette.grey[100]};
      background-color: rgba(0, 0, 0, .5);
    }
    ${(p) => p.doneStreamedText && `
      background: radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ) center bottom;
      background-repeat: no-repeat;
      background-size: 100% 14px;
      background-attachment: scroll;
    `}
  }
  .deflection-section {
    margin-bottom: 14px;
  }
  .SupportCaseBody__Additional-Details-Form {
    padding: 0 32px;
    ${p => p.theme.breakpoints.down('sm')} {
      border-top: 2px solid ${p => p.theme.palette.grey[300]} !important;
    }
  }
  .divider-left {
    border-left: 2px solid ${p => p.theme.palette.grey[300]};
    ${p => p.theme.breakpoints.down('sm')} {
      border: none;
    }
  }
  .divider-right {
    border-right: 2px solid ${p => p.theme.palette.grey[300]};
    ${p => p.theme.breakpoints.down('sm')} {
      border: none;
    }
  }
  .SupportCaseBody__Loader-Validation {
    display: flex;
    margin-top: 12px;
    font-size: 1.2rem;
    font-weight: bold;
    color: ${p => p.theme.palette.warning.main};
    .loader {
      color: ${p => p.theme.palette.warning.main};
    }
    .validation-text {
      margin-left: 6px;
    }
  }
  .SupportCaseBody__Types-Container {
    border-radius: ${p => p.theme.spacing(1)}px;
    overflow: hidden;
    border: 1px solid white;
    &:not(:last-child) {
      margin-bottom: ${p => p.theme.spacing(2)}px;
    }
    .SupportCaseBody__Type-Children-Container {
      padding: 12px;
      &--Open {
        display: block;
      }
      &--Closed {
        display: none;
      }
    }
  }
  .SupportCaseBody__Case-Name {
    color: ${p => p.theme.palette.semantic.colorForeground};
    &-Spacing {
      color: ${p => p.theme.palette.semantic.colorForeground};
      margin-bottom: -20px;
    }
  }
  .SupportCaseBody__Case-Description {
    color: ${p => p.theme.palette.semantic.colorForeground};
    line-height: 2rem;
  }
  .SupportCaseBody__Case-Text {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .SupportCaseBody__Case-Button {
    margin-left: 8px;
  }
  .SupportCaseBody__Case-Fields {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
    .MuiFormLabel-root.MuiInputLabel-root,
    .MuiInputBase-root {
      font-family: ${p => p.theme.typography.fontFamily};
      &.MuiOutlinedInput-adornedEnd {
        background: ${p => p.theme.palette.semantic.colorBackground};
      }
    }
    .MuiInputLabel-outlined {
      z-index: unset;
    }
    .SupportCaseBody__Case-Field {
      margin-bottom: ${p => p.theme.spacing(2)}px;
      font-family: ${p => p.theme.typography.fontFamily};
      color: ${p => p.theme.palette.semantic.colorForeground};
      .MuiFormHelperText-root {
        font-family: ${p => p.theme.typography.fontFamily};
      }
      &--Textarea {
        color: ${p => p.theme.palette.semantic.colorForeground};
        height: 130px;
        padding: 6px 8px;
        font-size: 14px;
        background-color: ${p => p.theme.palette.semantic.colorBackground};
        border-color: ${p => p.theme.palette.semantic.colorBorder};
        border-radius: 3px;
      }
      &--Upload-Form {
        display: none;
      }
      &--Upload-Button {
        color: ${p => p.theme.palette.semantic.colorForeground};
        border-color: #e3e3e3;
        .MuiButton-startIcon img {
          width: 15px;
        }
        height: 40px;
        background-color: ${p => p.theme.palette.semantic.colorBackground};
      }
      &--Upload-File-Name {
        display: flex;
        .icon {
          color: ${p => p.theme.palette.semantic.colorForeground};
        }
      }
      input.MuiInputBase-input {
        border: none;
      }
      .css-wg2rgq-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
        border: none !important;
      }
      &--Textfield .MuiInputBase-input {
        border: 1px solid ${p => p.theme.palette.semantic.colorBorder} !important;
        background-color: ${p => p.theme.palette.semantic.colorBackground};
      }

    }
    .SupportCaseBody__Case-Field--Error {
      color: ${p => p.theme.palette.error.main};
      display: block;
      margin-top: 12px;
      font-size: 1.2rem;
      font-weight: bold;
    }
    .SupportCaseBody__Case-Field--Success-Color {
      color: ${p => p.theme.palette.success.main};
      line-height: 20px;
    }
    .SupportCaseBody__Case-Field--Warning-Color {
      color: ${p => p.theme.palette.warning.main};
    }
    .SupportCaseBody__Case-Field--Error-Color {
      color: ${p => p.theme.palette.error.main};
    }
  }
  .SupportCaseBody__Radio-Container {
    flex-direction: row;
  }
  .SupportCaseBody__Radio {
    margin-left: 8px;
    flex: 1;
    &:last-child {
      transform: translateX(-100px);
      ${p => p.theme.breakpoints.down('sm')} {
        transform: translateX(-150px);
      }
    }
  }
  a {
    color: ${p => p.theme.palette.semantic.colorPrimary};
    font-weight: 500;
  }

  p {
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  .MuiFormControl-root textarea {
    line-height: 22px;
  }
  .loadingIcon {
    animation: spin 4000ms infinite linear;
    font-size: 80px;
    margin: 10% 40%;;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
`;

export const FilterItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }
  padding: 10px ${p => p.theme.spacing(2)}px;
  cursor: pointer;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  border-radius: ${p => p.theme.spacing(1)}px;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: normal;
  color: ${p => p.theme.palette.semantic.colorForeground};
  ${transitionStandard('all')};
  &:hover {
    background-color: ${p => p.theme.palette.semantic.colorPrimaryDarker};
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
  &.SupportCaseBody__Case-Filter-Item--Active {
    color: ${p => p.theme.palette.semantic.colorForeground};
    background-color: ${p => p.theme.palette.semantic.colorPrimary};
    &:hover {
      background: ${p => p.theme.palette.semantic.colorPrimary};
      color: ${p => p.theme.palette.semantic.colorForeground};
    }
  }
`;

export const heroTitle = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 24px;
  color: ${p => p.theme.palette.semantic.colorForeground};
  margin: ${p => p.theme.spacing(2)}px 0 ${p => p.theme.spacing(4)}px 0;
`;

export const backButton = styled(Link)`
  font-size: 12px;
  color: ${p => p.theme.palette.semantic.colorForeground};
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    background: url(${NavIcon}) no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
`;

export const EmailDomainValidationModalContainer = styled.div`
  margin-top: ${p => p.theme.spacing(4)}px;
  font-size: 1.4rem;

  .EmailDomainValidationModalContainer__Buttons {
    display: flex;
    justify-content: end;
  }
`;

export const navigationButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px;
  border-top: 2px solid ${p => p.theme.palette.grey[300]};
  height: 40px;

  .cancelBtn {
    width: 80px;
    color: ${p => p.theme.palette.semantic.colorPrimary};

    &:hover {
      background-color: ${p => p.theme.palette.semantic.colorBackgroundHover};
    }
  }

  .nextBtn, .submitBtn {
    width: 120px;
    margin-right: 40px;
    color: ${p => p.theme.palette.semantic.colorBackground};
    background-color: ${p => p.theme.palette.semantic.colorPrimary};
  }

  .backBtn {
    width: 120px;
    margin: 0px 10px;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border: 1px solid ${p => p.theme.palette.grey[500]};
    color: ${p => p.theme.palette.semantic.colorForeground};
  }

  .backBtn:disabled, .nextBtn:disabled {
    background-color: ${p => p.theme.palette.semantic.colorBackgroundDisabled} !important;
    color: ${p => p.theme.palette.grey[500]};
  }

  .nextBtn:focus, .submitBtn:focus {
    box-shadow: none !important;
  }
`;

export const popupHeading = styled.div`
  display: flex;
  margin-bottom: 20px;

  .warning-icon {
    color: ${p => p.theme.palette.warning.main};
    font-size: 38px;
  }

  .popup-title {
    margin-left: 20px;
    color: ${p => p.theme.palette.semantic.colorForeground};
  }
`;

export const popupDescription = styled.p`
  font-size: 18px;
  color: ${p => p.theme.palette.semantic.colorForeground};
`;

export const popupButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  height: 40px;

  .noBtn {
    width: 150px;
    margin-right: 20px;
    color: ${p => p.theme.palette.semantic.colorPrimary};
  }

  .yesBtn {
    width: 150px;
    background-color: ${p => p.theme.palette.semantic.colorPrimary};
    color: ${p => p.theme.palette.semantic.colorBackground};

    &:hover {
      box-shadow: none !important;
      background-color: ${p => p.theme.palette.semantic.colorPrimaryHover};
    }
  }
`;

export const loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  height: 100%;
`;
