import axios from 'axios';
import type { ReactNode } from 'react';
import React, {
  createContext,
  useEffect,
  useState,
} from 'react';

import { CLOUD_ORG_ADMINISTRATORS_GROUP } from '../../constants/cloud.constants';
import AuthUserUtil from '../../lib/auth.util';
import {
  getCloudOrganizationGroupsUrl,
  getCloudOrganizationInfoUrl,
  isCloudEnv,
} from '../../utils/cloud';
import type { IdentityUser } from '../auth';
import { useAuth } from '../auth';

export interface ICloudOrgContext {
  isLoadingCloudOrgDetails: boolean;
  cloudOrgId: string | null;
  cloudOrgLogicalName: string | null;
  cloudOrgName: string | null;
  isCloudOrgAdmin: boolean;
}

export const CloudOrgContext = createContext({
  isLoadingCloudOrgDetails: true,
  cloudOrgId: null,
  cloudOrgLogicalName: null,
  cloudOrgName: null,
  isCloudOrgAdmin: false,
} as ICloudOrgContext);

export const CloudOrgProvider = ({ children }: { children?: ReactNode }) => {
  const {
    user, isAuthenticated, getAccessToken,
  } = useAuth();
  const isCloud = isCloudEnv();

  const [ isLoadingCloudOrgDetails, setIsLoadingCloudOrgDetails ] = useState<boolean>(isCloud);
  const [ cloudOrgId, setCloudOrgId ] = useState<string | null>(null);
  const [ cloudOrgLogicalName, setCloudOrgLogicalName ] = useState<string | null>(null);
  const [ cloudOrgName, setCloudOrgName ] = useState<string | null>(null);
  const [ isCloudOrgAdmin, setIsCloudOrgAdmin ] = useState<boolean>(false);

  const setCloudOrgDetails = async (oidcUser: IdentityUser) => {
    const userCloudOrgId = AuthUserUtil.getCloudOrgId(oidcUser);
    setCloudOrgId(userCloudOrgId);

    try {
      const cloudOrgInfoResponse = await axios.get(getCloudOrganizationInfoUrl(), {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'Content-Type': 'application/json',
        },
        data: {}, // Required to allow the Content-Type header to be set with axios GET requests
      });

      setCloudOrgLogicalName(cloudOrgInfoResponse?.data?.accountUserDto?.accountLogicalName ?? null);
      setCloudOrgName(cloudOrgInfoResponse?.data?.accountUserDto?.companyName ?? null);

      const userGroupIds: string[] = cloudOrgInfoResponse?.data?.groups ?? [];

      if (userGroupIds.length) {
        // Fetch the group IDs of the Cloud Org
        const groupIdsOfCloudOrgResponse = await axios.get(getCloudOrganizationGroupsUrl(userCloudOrgId), {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'Content-Type': 'application/json',
          },
          data: {},
        });

        const groupNameToGroupId: Record<string, string> = groupIdsOfCloudOrgResponse
          .data?.reduce((record: Record<string, string>, group: any) => {
            record[group.name] = group.id;
            return record;
          }, {});

        const administratorGroupId = groupNameToGroupId[CLOUD_ORG_ADMINISTRATORS_GROUP];

        // Check if the user's groups include the Administrators group
        setIsCloudOrgAdmin(userGroupIds.includes(administratorGroupId));
      } else {
        setIsCloudOrgAdmin(false);
      }
    } catch (e) {
      console.error('An error occured while fetching Cloud Org details:', e);
    }
    setIsLoadingCloudOrgDetails(false);
  };

  useEffect(() => {
    if (isCloud && isAuthenticated && user) {
      setCloudOrgDetails(user as IdentityUser);
    }
  }, [ isCloud, isAuthenticated, user ]);

  const contextValue: ICloudOrgContext = {
    isLoadingCloudOrgDetails,
    cloudOrgId,
    cloudOrgLogicalName,
    cloudOrgName,
    isCloudOrgAdmin,
  };

  return (
    <CloudOrgContext.Provider value={contextValue}>
      {children}
    </CloudOrgContext.Provider>
  );
};
