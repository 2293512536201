import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, {
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Dropdown,
  DropdownOption,
  MainButton,
} from '../../assets/css/Support/CaseActionsButton';
import ApolloIcon from '../../components/ApolloIcon';
import {
  ActionType,
  AttachLogsValidationMessage,
  AttachLogsValidationMessageType,
  ElevateEscalationValidationMessage,
  ElevateEscalationValidationMessageType,
  EscalationValidationMessage,
  EscalationValidationMessageType,
  FollowUpValidationMessage,
  FollowUpValidationMessageType,
  ReopenValidationMessage,
  ReopenValidationMessageType,
} from '../../constants/caseActions.constants';
import type { DropDownItemData } from '../../interfaces/sfdc.interface';
import { StoreContext } from '../../store';
import ActionPanel from '../ActionPanel';
import AttachLogFiles from './CaseActions/AttachLogFiles';
import ElevateEscalation from './CaseActions/ElevateEscalation';
import EscalateCaseSupport from './CaseActions/EscalateCaseSupport';
import FollowUpCase from './CaseActions/FollowUpCase';
import ReopenCase from './CaseActions/ReopenCase';

const TooltipMessage = styled(({
  className, ...props
}: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${(p: any) => p.theme.palette.semantic.colorBackground} !important`,
    color: `${(p: any) => p.theme.palette.semantic.colorForeground} !important`,
    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: '24px',
    maxWidth: 'none',
  },
}));

export type CaseActionsButtonProps = {
  onOpen?: () => void;
  onClose?: () => void;
  onClick?: () => void;
  disabled: boolean;
  id: string;
  caseNumber: string;
  isClosed: boolean;
  isEscalated: boolean;
  isFRViolated: boolean;
  isFRCompleted: boolean;
  isClosedDueToOutOfSupport: boolean;
  isClosedMoreThan15Days: boolean;
  isClosedMoreThan180Days: boolean;
  isEscalatedRecently: boolean;
  isAllowedPriorityElevateEscalation: boolean;
  allowReopenClosedCase: boolean;
  followupCaseNumber: string;
  followupCaseSubject: string;
  timezone: string;
  contactNumber: string;
  setIsEscalated: (value: boolean) => void;
  setFollowupCaseNumber: (value: string) => void;
  escalationReasons: DropDownItemData[];
  followUpReasons: DropDownItemData[];
  reopenReasons: DropDownItemData[];
  isElevateEscalationEnabled: boolean;
  canBypassFirstResponseforCaseEscalation: boolean;
};

const CaseActionsButton = (props: CaseActionsButtonProps) => {
  const {
    disabled,
    isClosed,
    isEscalated,
    isFRViolated,
    isFRCompleted,
    isClosedDueToOutOfSupport,
    isClosedMoreThan15Days,
    isClosedMoreThan180Days,
    isEscalatedRecently,
    isAllowedPriorityElevateEscalation,
    allowReopenClosedCase,
    followupCaseNumber,
    followupCaseSubject,
    isElevateEscalationEnabled,
    canBypassFirstResponseforCaseEscalation,
  } = props;
  const { t } = useTranslation('common');
  const {
    state, dispatch,
  } = useContext(StoreContext);

  const [ anchorEl, setAnchorEl ] = useState<(EventTarget & HTMLDivElement) | null>(null);
  const [ actionType, setActionType ] = useState('None');

  const isPendingFR = canBypassFirstResponseforCaseEscalation ? false : !isFRViolated && !isFRCompleted;
  const disableEscalate = isClosed || isEscalated || isPendingFR;
  const disableElevateEscalation = isClosed || isEscalatedRecently || !isEscalated || !isAllowedPriorityElevateEscalation;
  const normalizedFollowupCaseSubject = followupCaseSubject.toLowerCase();
  const followedUp = followupCaseNumber !== '' && !normalizedFollowupCaseSubject.startsWith('dsat followup') && !normalizedFollowupCaseSubject.endsWith('dsat');
  const disableFollowUp = !isClosed || isClosedDueToOutOfSupport || followedUp || isClosedMoreThan180Days;
  const disableReopen = isClosedDueToOutOfSupport;
  const disableAttachLogs = isClosed;

  const togglePopover = (e: any) => {
    if (!e || e.currentTarget === anchorEl) {
      return;
    }
    setAnchorEl(e.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const selectOption = (actionType: string) => {
    dispatch({
      type: 'toggleActionPanel',
      payload: null,
    });
    closePopover();
    setActionType(actionType);
  };

  const handleEscalateClick = () => {
    if (!disableEscalate) {
      selectOption(ActionType.Escalate);
    }
  };

  const handleElevateEscalationClick = () => {
    if (!disableElevateEscalation) {
      selectOption(ActionType.ElevateEscalation);
    }
  };

  const handleFollowUpClick = () => {
    if (!disableFollowUp) {
      selectOption(ActionType.FollowUp);
    }
  };

  const handleReopenClick = () => {
    if (!disableReopen) {
      selectOption(ActionType.Reopen);
    }
  };

  const handleAttachLogsClick = () => {
    if (!disableAttachLogs) {
      selectOption(ActionType.AttachLogs);
    }
  };

  const getEscalateValidationMessageType = (): EscalationValidationMessageType => {
    if (isClosed) {
      return EscalationValidationMessageType.closed;
    }
    if (isEscalated) {
      return EscalationValidationMessageType.escalated;
    }
    if (isPendingFR) {
      return EscalationValidationMessageType.sla_active_and_not_expired;
    }
    return EscalationValidationMessageType.none;
  };

  const escalateDropdownOption = () => (
    <DropdownOption
      onClick={handleEscalateClick}
      className={disableEscalate ? 'disabled' : ''}
      data-testid={`escalate-button${disableEscalate ? '-disabled' : ''}`}
    >
      <div className='container'>
        <ApolloIcon
          className='icon'
          icon='trending_up'
        />
        {t('support_incident_cases_escalate_case', 'Escalate case')}
      </div>
    </DropdownOption>
  );

  const getElevateEscalationValidationMessageType = (): ElevateEscalationValidationMessageType => {
    if (isClosed) {
      return ElevateEscalationValidationMessageType.closed;
    }
    if (isEscalatedRecently) {
      return ElevateEscalationValidationMessageType.escalated_recently;
    }
    if (!isEscalated) {
      return ElevateEscalationValidationMessageType.not_escalated;
    }
    if (!isAllowedPriorityElevateEscalation) {
      return ElevateEscalationValidationMessageType.not_high_or_urgent_case;
    }
    return ElevateEscalationValidationMessageType.none;
  };

  const elevateEscalationDropdownOption = () => (
    <DropdownOption
      onClick={handleElevateEscalationClick}
      className={disableElevateEscalation ? 'disabled' : ''}
      data-testid={`elevate-escalation-button${disableElevateEscalation ? '-disabled' : ''}`}
    >
      <div className='container'>
        <ApolloIcon
          className='icon'
          icon='trending_up'
        />
        {t('support_incident_cases_elevate_escalation', 'Elevate escalation')}
      </div>
    </DropdownOption>
  );

  const getFollowUpValidationMessageType = (): FollowUpValidationMessageType => {
    if (!isClosed) {
      return FollowUpValidationMessageType.not_closed;
    }
    if (isClosedDueToOutOfSupport) {
      return FollowUpValidationMessageType.out_of_support;
    }
    if (isClosedMoreThan180Days) {
      return FollowUpValidationMessageType.days;
    }
    if (followedUp) {
      return FollowUpValidationMessageType.followed_up;
    }
    return FollowUpValidationMessageType.none;
  };

  const getFollowUpValidationMessageTextKey = (messageType: FollowUpValidationMessageType) => {
    if (messageType === FollowUpValidationMessageType.followed_up) {
      return t(
        FollowUpValidationMessage[messageType].localText,
        FollowUpValidationMessage[messageType].fallbackText,
        { followupCaseNumber }
      );
    }
    return t(
      FollowUpValidationMessage[messageType].localText,
      FollowUpValidationMessage[messageType].fallbackText
    );
  };

  const followUpDropdownOption = () => (
    <DropdownOption
      onClick={handleFollowUpClick}
      className={disableFollowUp ? 'disabled' : ''}
      data-testid={`follow-up-button${disableFollowUp ? '-disabled' : ''}`}
    >
      <div className='container'>
        <ApolloIcon
          className='icon'
          icon='replay'
        />
        {t('support_incident_cases_follow_up_case', 'Follow up case')}
      </div>
    </DropdownOption>
  );

  const getReopenValidationMessageType = (): ReopenValidationMessageType => isClosedDueToOutOfSupport ?
    ReopenValidationMessageType.out_of_support :
    ReopenValidationMessageType.none;

  const reopenDropdownOption = () => (
    <DropdownOption
      onClick={handleReopenClick}
      className={disableReopen ? 'disabled' : ''}
      data-testid={`reopen-button${disableReopen ? '-disabled' : ''}`}
    >
      <div className='container'>
        <ApolloIcon
          className='icon'
          icon='replay'
        />
        {t('support_incident_cases_reopen_case', 'Reopen case')}
      </div>
    </DropdownOption>
  );

  const getAttachLogsValidationMessageType = (): AttachLogsValidationMessageType => isClosed ?
    AttachLogsValidationMessageType.closed :
    AttachLogsValidationMessageType.none;

  const attachLogsDropdownOption = () => (
    <DropdownOption
      onClick={handleAttachLogsClick}
      className={disableAttachLogs ? 'disabled' : ''}
      data-testid={`attach-logs-button${disableAttachLogs ? '-disabled' : ''}`}
    >
      <div className='container'>
        <ApolloIcon
          className='icon'
          icon='attach_file'
        />
        {t('support_incident_cases_attach_log_files', 'Attach log files')}
      </div>
    </DropdownOption>
  );

  return (
    <div data-testid="CaseActionsButton">
      <MainButton
        disableRipple
        disabled={disabled}
        onClick={togglePopover}
        data-testid="caseActions-button"
      >
        {t('support_incident_cases_case_actions', 'Case actions')}
        <ApolloIcon
          className='icon'
          icon={!anchorEl && !state.showActionPanel ? 'expand_more' : 'expand_less'}
        />
      </MainButton>
      <Dropdown
        data-textid="CaseActionsButton__Dropdown"
        open={Boolean(anchorEl)}
        onClose={closePopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {getEscalateValidationMessageType() !== EscalationValidationMessageType.none ? (
          <TooltipMessage
            title={t<string>(
              EscalationValidationMessage[getEscalateValidationMessageType()].localText,
              EscalationValidationMessage[getEscalateValidationMessageType()].fallbackText
            )}
            placement='bottom'
            id={getEscalateValidationMessageType()}
          >
            {escalateDropdownOption()}
          </TooltipMessage>
        ) : (
          <div>
            {escalateDropdownOption()}
          </div>
        )}

        {isElevateEscalationEnabled && (
          getElevateEscalationValidationMessageType() !== ElevateEscalationValidationMessageType.none ? (
            <TooltipMessage
              title={t<string>(
                ElevateEscalationValidationMessage[getElevateEscalationValidationMessageType()].localText,
                ElevateEscalationValidationMessage[getElevateEscalationValidationMessageType()].fallbackText
              )}
              placement='bottom'
              id={getElevateEscalationValidationMessageType()}
            >
              {elevateEscalationDropdownOption()}
            </TooltipMessage>
          ) : (
            <div>
              {elevateEscalationDropdownOption()}
            </div>
          )
        )}

        {(!isClosed || (isClosed && (!allowReopenClosedCase || isClosedMoreThan15Days))) && (
          getFollowUpValidationMessageType() !== FollowUpValidationMessageType.none ? (
            <TooltipMessage
              title={getFollowUpValidationMessageTextKey(getFollowUpValidationMessageType())}
              placement='bottom'
              id={getFollowUpValidationMessageType()}
              className='tooltip-message'
            >
              {followUpDropdownOption()}
            </TooltipMessage>
          ) : (
            <div>
              {followUpDropdownOption()}
            </div>
          )
        )}

        {allowReopenClosedCase && isClosed && !isClosedMoreThan15Days && (
          getReopenValidationMessageType() !== ReopenValidationMessageType.none ? (
            <TooltipMessage
              title={t<string>(
                ReopenValidationMessage[getReopenValidationMessageType()].localText,
                ReopenValidationMessage[getReopenValidationMessageType()].fallbackText
              )}
              placement='bottom'
              id={getReopenValidationMessageType()}
              className='tooltip-message'
            >
              {reopenDropdownOption()}
            </TooltipMessage>
          ) : (
            <div>
              {reopenDropdownOption()}
            </div>
          )
        )}

        {getAttachLogsValidationMessageType() !== AttachLogsValidationMessageType.none ? (
          <TooltipMessage
            title={t<string>(
              AttachLogsValidationMessage[getAttachLogsValidationMessageType()].localText,
              AttachLogsValidationMessage[getAttachLogsValidationMessageType()].fallbackText
            )}
            placement='bottom'
            id={getAttachLogsValidationMessageType()}
            className='tooltip-message'
          >
            {attachLogsDropdownOption()}
          </TooltipMessage>
        ) : (
          <div>
            {attachLogsDropdownOption()}
          </div>
        )}
      </Dropdown>

      {actionType === ActionType.Escalate && (
        <ActionPanel
          content={
            <EscalateCaseSupport
              {...props}
              setActionType={setActionType}
            />
          }
          setActionType={setActionType}
        />
      )}

      {actionType === ActionType.ElevateEscalation && (
        <ActionPanel
          content={
            <ElevateEscalation
              {...props}
              setActionType={setActionType}
            />
          }
          setActionType={setActionType}
        />
      )}

      {actionType === ActionType.FollowUp && (
        <ActionPanel
          content={
            <FollowUpCase
              {...props}
              setActionType={setActionType}
            />
          }
          setActionType={setActionType}
        />
      )}

      {actionType === ActionType.Reopen && (
        <ActionPanel
          content={
            <ReopenCase
              {...props}
              setActionType={setActionType}
            />
          }
          setActionType={setActionType}
        />
      )}

      {actionType === ActionType.AttachLogs && (
        <ActionPanel
          content={
            <AttachLogFiles
              {...props}
              setActionType={setActionType}
            />
          }
          setActionType={setActionType}
        />
      )}
    </div>
  );
};

export default CaseActionsButton;
