import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { CloudPromptBannerContainer } from '../assets/css/CloudPromptBanner';
import { axiosPost } from '../client/axios';
import {
  CLOUD_DOMAIN,
  SERVICE_REDIRECT_KEY,
  SERVICE_REDIRECT_URI_KEY,
  SERVICE_REDIRECT_VALUE,
} from '../constants/auth.constants';
import { UPDATE_CLOUD_BANNER_SETTINGS_URL } from '../constants/network.constants';
import {
  CLOUD_CONNECT_REMIND_LATER,
  CLOUD_CONNECT_THROUGH_BANNER,
} from '../constants/telemetry.constants';
import { useAuth } from '../contexts/auth';
import { useTriggerTrackEventWithStateData } from '../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../lib/UserPermissions';
import { StoreContext } from '../store';
import { isCloudEnv } from '../utils/cloud';
import { clearSession } from '../utils/session';

const CloudPromptBanner = () => {
  const { t } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const [ showCloudBanner, setShowCloudBanner ] = useState(false);
  const hasMoveToCloudPermissions = UserPermissionsHelper.isMoveToCloudAllowed();
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  useEffect(() => {
    const lastCloudBannerClosedDate = state.currentUserCompany?.userCompany?.lastCloudBannerClosedDate;
    const isCompanyOnCloud = !!(state.currentUserCompany?.additionalCompanyInfo?.cloudOrgId);
    let closedMoreThan30Days;
    if (lastCloudBannerClosedDate) {
      closedMoreThan30Days = moment(lastCloudBannerClosedDate).isBefore(moment().subtract(30, 'days'));
    }

    if (!isCloudEnv() && hasMoveToCloudPermissions && !isCompanyOnCloud && (!lastCloudBannerClosedDate || closedMoreThan30Days)) {
      setShowCloudBanner(true);
    } else {
      setShowCloudBanner(false);
    }
  }, [
    hasMoveToCloudPermissions,
    state.currentUserCompany?.additionalCompanyInfo?.cloudOrgId,
    state.currentUserCompany?.userCompany?.lastCloudBannerClosedDate,
  ]);

  const onRemindMeLater = async () => {
    try {
      const response = await axiosPost(UPDATE_CLOUD_BANNER_SETTINGS_URL,
        state.companyId,
        await getAccessToken(),
      );

      if (response.status === 200) {
        setShowCloudBanner(false);
        triggerTrackEventWithStateData(CLOUD_CONNECT_REMIND_LATER, {});
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onConnectToCloud = () => {
    triggerTrackEventWithStateData(CLOUD_CONNECT_THROUGH_BANNER, {});
    // Note: Calling clearSession (which is normally called on logout) to clear client storage when navigating between Cloud/non-Cloud is to
    // ensure that the user's last-selected-company is preserved when navigating between Cloud/non-Cloud on deployed environments.
    // (Otherwise: if the user has company A selected on Cloud, then goes to non-Cloud on same tab and switches to company B
    // (different session-storage for different domain), then navigates back to Cloud, company A would be selected due to
    // the original Cloud's session-storage being read. We want to instead use user's last-selected-company in DB.)
    clearSession();
    window.location.href = `${CLOUD_DOMAIN}/portal_/cloudrpa?${SERVICE_REDIRECT_KEY}=${SERVICE_REDIRECT_VALUE}&${SERVICE_REDIRECT_URI_KEY}=/cloud-migration`;
  };

  return (
    <div>
      {showCloudBanner && (
        <CloudPromptBannerContainer showBanner={showCloudBanner}>
          <div>
            {t('cloud_prompt_banner_message', 'Connect Customer Portal to your UiPath Automation Cloud. Manage all your users from a single location and have a more streamlined experience.')}
            <button onClick={onConnectToCloud}>{t('cloud_prompt_banner_connect_button', 'Connect to Cloud')}</button>
            <button onClick={onRemindMeLater}>{t('cloud_prompt_banner_remind_button', 'Remind me later')}</button>
          </div>
        </CloudPromptBannerContainer>
      )}
    </div>
  );
};

export default CloudPromptBanner;
